<template>
  <div v-if="isExternal" class="menu-link-new" :class="{ 'menu-animate-in': animateIn }">
    <a :href="link.path" target="_blank"><slot></slot> </a>
  </div>
  <div v-else class="menu-link" :class="{ 'menu-animate-in': animateIn, 'menu-link-new': isNewLink }">
    <a
      @click="
        $emit(
          'close-menu',
          link.path,
          link.familyExplorer ? true : false,
          parent ? parent.name : false
        )
      "
      @keypress.enter="
        $emit(
          'close-menu',
          link.path,
          link.familyExplorer ? true : false,
          parent ? parent.name : false
        )
      "
      :class="{
        'router-link-active router-link-exact-active':
          currentPath === link.path,
        'menu-link--disabled': comingSoon,
      }"
      :aria-label="aria"
      tabindex="0"
    >
      <slot></slot>
    </a>
  </div>
</template>

<script>
export default {
  emits: ["close-menu"],
  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
    isNewLink: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      required: true,
    },
    comingSoon: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Object,
      default: () => {
        return {};
      },
    },
    aria: {
      type: String,
      default: "",
    },
    currentPath: {
      type: String,
      defautl: "/",
    },
    animateIn: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { props };
  },
};
</script>

<style lang="scss" scoped>
.menu-link {
  &--disabled {
    pointer-events: none;
    touch-action: none;

    color: #9b9b9b !important;
  }

  a {
    font-size: 20px;
    line-height: 1;
    cursor: pointer;
    text-decoration: none;
    color: $extraDarkGray;
    @include text(body);

    &.router-link-exact-active {
      color: $blue;
      pointer-events: none;
      touch-action: none;
    }

    &:hover {
      color: $blue;
    }
  }
}

.menu-link-new {
  &--disabled {
    pointer-events: none;
    touch-action: none;

    color: #9b9b9b !important;
  }

  a {
    font-size: 20px;
    line-height: 1;
    cursor: pointer;
    text-decoration: none;
    color: $extraDarkGray;
    @include text(body);

    &.router-link-exact-active {
      color: $blue;
      pointer-events: none;
      touch-action: none;
    }

    &:hover {
      color: $blue;
    }
  }

  // background-color: rgb(252, 252, 205);
  // margin-bottom: 10px;
  padding: 10px 0;
  border-top: 1px solid #bdbdbd;
  // border-bottom: 1px solid #bdbdbd;
}

</style>
