<template>
  <div class="guide">
    <transition name="fade">
      <div
        v-if="currentGuide && $store.state.currentScenario.guideIsShowing"
        class="guide__box"
        :class="position"
        :style="{ maxWidth: maxWidth }"
      >
        <div class="guide__content" v-html="$sanitize($t(currentGuide.text))" />
        <div
          v-if="
            currentGuide.prevButton ||
              currentGuide.nextButton ||
              currentGuide.finishButton
          "
          class="guide__buttons"
        >
          <div>
            <ButtonText
              v-if="currentGuide.prevButton"
              text="general.scenes.back"
              @click="
                () => {
                  this.$store.dispatch('prev');
                }
              "
              arrow="left"
              id="actionButton"
            />
          </div>
          <div>
            <ButtonText
              v-if="currentGuide.nextButton"
              text="general.scenes.next"
              @click="
                () => {
                  this.$store.dispatch('next');
                }
              "
              id="actionButton"
            />
            <ButtonText
              v-else-if="currentGuide.finishButton"
              text="general.scenes.finish"
              @click="
                () => {
                  this.$store.dispatch('next');
                }
              "
              id="actionButton"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import guideData from "@/data/guide";
import ButtonText from "@/components/common/ButtonText";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "Guide",
  components: {
    ButtonText,
  },
  setup(props) {
    const store = useStore();

    const currentGuide = computed(() => {
      if (pageIndex.value !== 0 && guideData) {
        let guideForScreen =
          guideData?.[store.state.currentScenario.guideId]?.[pageIndex.value];
        if (guideForScreen?.iframe) {
          return guideForScreen?.[
            store.state?.currentScenario?.activeIframeData?.screenId
          ];
        } else {
          return guideForScreen;
        }
      }
      return null;
    });

    const pageIndex = computed(() => {
      return store.state.pageIndex;
    });

    const position = computed(() => {
      let returnVal = ["guide__box--Hcenter", "guide__box--top"];
      if (currentGuide.value?.position) {
        if (currentGuide.value.position.x) {
          returnVal[0] =
            currentGuide.value.position.x === "center"
              ? `guide__box--H${currentGuide.value.position.x}`
              : `guide__box--${currentGuide.value.position.x}`;
        }
        if (currentGuide.value.position.y) {
          returnVal[1] =
            currentGuide.value.position.y === "center"
              ? `guide__box--V${currentGuide.value.position.y}`
              : `guide__box--${currentGuide.value.position.y}`;
        }
      }
      return returnVal;
    });

    const maxWidth = computed(() => {
      let returnMaxWidth = "250px";
      if (currentGuide.value && currentGuide.value.maxWidth) {
        returnMaxWidth = currentGuide.value.maxWidth;
      }
      return returnMaxWidth;
    });

    return { props, pageIndex, guideData, position, currentGuide, maxWidth };
  },
};
</script>

<style lang="scss" scoped>
.guide {
  $extraPadding: 112px;

  position: absolute;
  pointer-events: none;
  touch-action: none;
  top: 0;
  right: 0;
  z-index: 29;
  width: calc(100vw - (#{$absoluteOuterPadding} * 2));
  height: calc(100vh - (#{$absoluteOuterPadding} * 2) - 92px);
  box-sizing: border-box;
  margin: calc(#{$absoluteOuterPadding} + 46px) $absoluteOuterPadding;

  &__box {
    position: absolute;
    padding: 12px;
    border-radius: 3px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    right: 0;
    top: 0;
    @include backgroundBlur(#fff, 0.975, 0.8, 15px);
    max-width: 600px;
    pointer-events: all;
    touch-action: auto;

    &--top {
      top: 0;
    }

    &--bottom {
      top: initial;
      bottom: 0;
    }
    &--Vcenter {
      top: 50%;
      transform: translateY(-50%);
    }

    @include breakpoint(mobile) {
      max-height: 50px;
      overflow: auto;
    }
    @include breakpoint(tablet) {
      max-height: 60px;
      overflow: auto;
    }
  }

  &__content {
    @include text(body);

    color: $extraDarkGray;
    font-size: 15px;

    :deep(p),
    p {
      margin-bottom: 12px !important;
    }
  }

  &__buttons {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
  }
}
</style>
