import { createI18n } from "vue-i18n"; // import from runtime only

import { store } from "@/data/store.js";

// importing de json files to translate everything
// add a new language mean that you need to import a new json file
import en_us from "./locales/en.json";
import es_es from "./locales/es.json";
import de_de from "./locales/de.json";
import fr_fr from "./locales/fr.json";
import ja_jp from "./locales/jp.json";

import VALID_LANGUAGES from "./i18nLanguage";

const DEFAULT_LANGUAGE = VALID_LANGUAGES[0]; // just in case that actual locale is not valid

const isValidLang = lang => {
  return VALID_LANGUAGES.includes(lang.toLowerCase());
};

let language = "";
if (window.location.hash.split("/").length > 1) {
  language = window.location.hash.split("/")[1];
}

//the first run of the web site the URL is always "/"
// and them the router redirect to the user default language i.e "/en-us"
// so we set on the store the actual user language and if we got it, them use it
if (!language && store.state.actualLang) {
  language = store.state.actualLang;
}

if (!language || !isValidLang(language)) {
  language = DEFAULT_LANGUAGE;
}

store.commit("updateActualLang", language.toLowerCase());

// if you are adding a new language, add the new json file on the "messages" object
const i18n = createI18n({
  locale: store.state.actualLang.replace("-", "_"),
  fallbackLocale: "en_us",
  messages: {
    en_us,
    es_es,
    de_de,
    fr_fr,
    ja_jp,
  },
  warnHtmlInMessage: "off",
  silentFallbackWarn: true,
});

export default i18n;

/*

  */
