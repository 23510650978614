<template>
  <button
    type="button"
    class="filter"
    v-on:click="showFilterMenu"
    aria-label="filter devices"
    :tabindex="isSelectable ? 0 : -1"
  >
    <p>Filter Devices</p>
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99988 8L12.7992 16.5806L19.5986 8"
        stroke="#3A3A3A"
        stroke-width="2"
      />
      <rect
        x="0.25"
        y="0.25"
        width="24.5"
        height="24.5"
        rx="2.75"
        stroke="#3A3A3A"
        stroke-width="0.5"
      />
    </svg>
  </button>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    isSelectable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();

    const showFilterMenu = () => {
      store.commit("updateFilterOpen", !store.state.isFilterOpen);
    };

    const isFilterMenuActive = computed(() => {
      return store.state.isFilterOpen;
    });

    return { showFilterMenu, isFilterMenuActive };
  },
};
</script>
<style lang="scss" scoped>
.filter {
  display: flex;
  align-items: center;
  margin: 2px 24px 0px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:focus-visible svg {
    outline: -webkit-focus-ring-color auto 1px;
    border-radius: 2px;
  }

  p {
    @include text(bodyBold);
    color: $extraDarkGray;
    padding-bottom: 0px;
    margin-right: 16px;
  }

  .filter_menu {
    position: static;
    background-color: #f4f4f4;
    top: 60px;
    right: 10px;
  }

  .close {
    display: none;
  }
}
</style>
