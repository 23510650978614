/*
    Text
      text: "path to string in local files"
    Max Width  (default: 250px)
      maxWidth: "300px"
    Add Previous Button (default: false)
      prevButton: true
    Add Next Button (default: false)
      nextButton: true
    Add Finish Button (default: false)
      finishButton: true
*/

export default {
  surfaceHub_A: {
    0: {
      text: "general.scenes.surfaceHub_A.guide.0",
    },
    1: {
      iframe: true,
      "c4ae0665-1e54-4f07-a1dd-06040f68a7d4": {
        text: "general.scenes.surfaceHub_A.guide.1.iframe1",
      },
    },
    3: {
      text: "general.scenes.surfaceHub_A.guide.3",
      //   maxWidth: "400px",
    },
    7: {
      iframe: true,
      firstScreen: {
        text: "general.scenes.surfaceHub_A.guide.7.iframe1",
      },
    },
    8: {
      text: "general.scenes.surfaceHub_A.guide.8",
      //   maxWidth: "280px",
      //   nextButton: true,
    },
    9: {
      iframe: true,
      "34499ff5-5cfd-4d0c-8952-1cee82b69ac0": {
        text: "general.scenes.surfaceHub_A.guide.9.iframe1",
      },
    },
  },
  surfaceHub_B: {
    0: {
      text: "general.scenes.surfaceHub_B.guide.0",
    },
    2: {
      text: "general.scenes.surfaceHub_B.guide.2",
      //   maxWidth: "400px",
    },
    3: {
      iframe: true,
      "1b7197a3-4fc3-41fe-b196-150d442de9e6": {
        text: "general.scenes.surfaceHub_B.guide.3.iframe1",
      },
    },
    4: {
      text: "general.scenes.surfaceHub_B.guide.4",
      //   maxWidth: "400px",
    },
  },
  surfaceHub_C: {
    0: {
      text: "general.scenes.surfaceHub_C.guide.0",
    },
    1: {
      text: "general.scenes.surfaceHub_C.guide.1",
    },
    2: {
      text: "general.scenes.surfaceHub_C.guide.3",
    },
  },
  surfaceHub_D: {
    0: {
      text: "general.scenes.surfaceHub_D.guide.0",
    },
    3: {
      text: "general.scenes.surfaceHub_D.guide.3",
    },
    5: {
      text: "general.scenes.surfaceHub_D.guide.5",
      //   finishButton: true,
    },
  },
  pro9_A: {
    0: {
      text: "general.scenes.pro9_A.guide.0",
    },
    1: {
      iframe: true,
      "38738bd6-ef7c-4de6-a27a-fa22932375d2": {
        text: "general.scenes.pro9_A.guide.1.iframe1",
      },
      "3e1aeb5b-7c77-43a3-805a-cf438ec8f700": {
        text: "general.scenes.pro9_A.guide.1.iframe2",
      },
      "c8750691-3245-420c-815e-a0aa97e28494": {
        text: "general.scenes.pro9_A.guide.1.iframe3",
      },
      "0d50e4b2-56d8-4579-bf68-cb88dc9ffca8": {
        text: "general.scenes.pro9_A.guide.1.iframe4",
      },
      "c9ae44f2-13d8-4267-b200-13aee7452da6": {
        text: "general.scenes.pro9_A.guide.1.iframe5",
      },
    },
    2: {
      text: "general.scenes.pro9_A.guide.2",
    },
    3: {
      text: "general.scenes.pro9_A.guide.3",
    },
    4: {
      iframe: true,
      "a1a5add5-c13f-4860-9e70-326b599f1111": {
        text: "general.scenes.pro9_A.guide.4.iframe1",
      },
      "8d0a6c63-df23-44b6-93fd-3d7a04e4fc12": {
        text: "general.scenes.pro9_A.guide.4.iframe2",
      },
      "e04e40f7-e937-4a8e-8212-a844ddb06cc2": {
        text: "general.scenes.pro9_A.guide.4.iframe3",
      },
      "25062ce4-1420-46b7-8620-a510ca501218": {
        text: "general.scenes.pro9_A.guide.4.iframe4",
      },
      "bc920d08-7df7-412b-9bbd-751706f82334": {
        text: "general.scenes.pro9_A.guide.4.iframe5",
      },
      "1a968f02-38a2-4e5e-b8c3-afb8416493e3": {
        text: "general.scenes.pro9_A.guide.4.iframe6",
      },
      "f055fb96-afd3-4929-a4b8-8ca8f6470438": {
        text: "general.scenes.pro9_A.guide.4.iframe7",
      },
    },
  },
  pro9_B: {
    0: {
      text: "general.scenes.pro9_B.guide.0",
    },
    1: {
      iframe: true,
      "24744dfd-4946-4d37-acfc-a10ea723941d": {
        text: "general.scenes.pro9_B.guide.1.iframe1",
      },
      "ca4bd019-9326-46c1-9255-07fb2bebbbc3": {
        text: "general.scenes.pro9_B.guide.1.iframe2",
      },
      "46c54898-7ee2-4153-88e3-7eb9440d54d4": {
        text: "general.scenes.pro9_B.guide.1.iframe3",
      },
      "09caec3e-6362-423c-a38b-a092a062cbc4": {
        text: "general.scenes.pro9_B.guide.1.iframe4",
      },
      "26c213e3-9ccc-415d-98a7-e14973898ab9": {
        text: "general.scenes.pro9_B.guide.1.iframe5",
      },
      "5df7c65a-f198-467c-b530-41a87e91bb8e": {
        text: "general.scenes.pro9_B.guide.1.iframe6",
      },
      "eb542996-02da-4257-aae0-73969f92ac7b": {
        text: "general.scenes.pro9_B.guide.1.iframe7",
      },
      "3acdb076-5c1b-4348-bff9-e8c72354cef9": {
        text: "general.scenes.pro9_B.guide.1.iframe8",
      },
      "3edec3fe-898f-48cb-aa9b-854ce330b336": {
        text: "general.scenes.pro9_B.guide.1.iframe9",
      },
      "55bae305-ac1a-41b5-8cb8-9f5f0e974145": {
        text: "general.scenes.pro9_B.guide.1.iframe10",
      },
      "52f8873b-53fb-4e90-9b9d-d8280a73d179": {
        text: "general.scenes.pro9_B.guide.1.iframe11",
      },
    },
    3: {
      iframe: true,
      firstScreen: {
        text: "general.scenes.pro9_B.guide.3.iframe1",
      },
      "888927be-63fa-44ee-a2b1-4d615383ddf5": {
        text: "general.scenes.pro9_B.guide.3.iframe1",
      },
      "640e8f96-ca66-4c55-97e0-af4a20d2de45": {
        text: "general.scenes.pro9_B.guide.3.iframe2",
      },
      "af8b448d-ad79-4b6f-8365-b5bdb73621ac": {
        text: "general.scenes.pro9_B.guide.3.iframe3",
      },
      "e696197a-9efc-45fa-bd37-8c368f7cfb38": {
        text: "general.scenes.pro9_B.guide.3.iframe4",
      },
    },
  },
  pro9_C: {
    0: {
      text: "general.scenes.pro9_C.guide.0",
    },
    1: {
      iframe: true,
      "0bd18ad3-2fba-43a2-a92e-ec6b62144a01": {
        text: "general.scenes.pro9_C.guide.1.iframe1",
      },
      "c8223359-b4d3-405c-9508-3022bdc8fdb0": {
        text: "general.scenes.pro9_C.guide.1.iframe2",
      },
      "99f5c832-cd10-4c4b-8605-541e52b781ee": {
        text: "general.scenes.pro9_C.guide.1.iframe3",
      },
    },
    2: {
      text: "general.scenes.pro9_C.guide.2",
    },
    6: {
      iframe: true,
      "e5707078-dcb4-42f1-b0f1-0c3cce13fcd0": {
        text: "general.scenes.pro9_C.guide.6.iframe1",
      },
      "78716941-fb5b-435e-9d08-525059545485": {
        text: "general.scenes.pro9_C.guide.6.iframe2",
      },
      "e0b17b53-8179-47e3-85f1-14943321bb15": {
        text: "general.scenes.pro9_C.guide.6.iframe3",
      },
      "186b10ab-5993-41b2-8950-45d7e9cea697": {
        text: "general.scenes.pro9_C.guide.6.iframe4",
      },
    },
  },
  laptopStudio_A: {
    0: {
      text: "general.scenes.laptopStudio_A.guide.0",
    },
    1: {
      iframe: true,
      "dc090cb2-1f13-4b7a-8931-243ec146bf88": {
        text: "general.scenes.laptopStudio_A.guide.1.iframe1",
      },
    },
    2: {
      text: "general.scenes.laptopStudio_A.guide.2",
    },
    6: {
      iframe: true,
      firstScreen: {
        text: "general.scenes.laptopStudio_A.guide.6.iframe1",
      },
    },
    7: {
      iframe: true,
      "db3c0d30-c61c-43a0-8b31-fd55aae1dbfa": {
        text: "general.scenes.laptopStudio_A.guide.7.iframe1",
      },
      "75732563-1245-4dd1-83d0-524ff1768a5e": {
        text: "general.scenes.laptopStudio_A.guide.7.iframe2",
      },
    },
  },
  laptopStudio_B: {
    0: {
      text: "general.scenes.laptopStudio_B.guide.0",
    },
    1: {
      iframe: true,
      "f78a0091-e2dc-4412-b150-a5092c367cb1": {
        text: "general.scenes.laptopStudio_B.guide.1.iframe1",
      },
      "76a22e85-333b-4bf9-b8be-ec31bc4cb6a7": {
        text: "general.scenes.laptopStudio_B.guide.1.iframe2",
      },
    },
    2: {
      text: "general.scenes.laptopStudio_B.guide.2",
      //   maxWidth: "400px",
    },
    3: {
      text: "general.scenes.laptopStudio_B.guide.3",
    },
    5: {
      iframe: true,
      "d986adbe-07ad-4251-aebb-3314216bf212": {
        text: "general.scenes.laptopStudio_B.guide.5.iframe1",
      },
      "c0861950-bcc2-4534-ab92-ec73ac5c67a4": {
        text: "general.scenes.laptopStudio_B.guide.5.iframe2",
      },
    },
    6: {
      text: "general.scenes.laptopStudio_B.guide.6",
    },
  },
  Duo_A: {
    0: {
      text: "general.scenes.duo_A.guide.0",
    },
    1: {
      iframe: true,
      "7d673ca3-cd7f-487e-a963-ca84380b8f12": {
        text: "general.scenes.duo_A.guide.1.iframe1",
      },
      "adfeff5b-da99-4b85-873c-209809e2a7e3": {
        text: "general.scenes.duo_A.guide.1.iframe2",
      },
      "813df600-35b6-4eff-9cec-4ac0a6e9e203": {
        text: "general.scenes.duo_A.guide.1.iframe3",
      },
      "7b2172b4-9ff2-4f2a-a4f5-a19e0b55b556": {
        text: "general.scenes.duo_A.guide.1.iframe4",
      },
    },
    2: {
      text: "general.scenes.duo_A.guide.2",
      //   maxWidth: "400px",
      //   nextButton: true,
    },
    4: {
      iframe: true,
      "d257f12c-4b8a-4b32-a1d3-95bf19a7f96e": {
        text: "general.scenes.duo_A.guide.4.iframe1",
        position: {
          y: "bottom",
        },
      },
      "729c4c8f-2591-4375-a522-f4ca6ebaf0f7": {
        text: "general.scenes.duo_A.guide.4.iframe2",
        position: {
          y: "bottom",
        },
      },
      "4d34c01f-c7d7-4908-a2d4-11e16b3c01e8": {
        text: "general.scenes.duo_A.guide.4.iframe3",
        position: {
          y: "bottom",
        },
      },
    },
    8: {
      iframe: true,
      "60f73bbb-1302-4ad9-92da-137fefd37c0c": {
        text: "general.scenes.duo_A.guide.8.iframe1",
        position: {
          y: "bottom",
        },
      },
    },
  },
  Duo_B: {
    0: {
      text: "general.scenes.duo_B.guide.0",
    },
    1: {
      iframe: true,
      "813e1b29-2c00-4b6a-aafd-68994248ce1a": {
        text: "general.scenes.duo_B.guide.1.iframe1",
      },
      "1d45d2ca-e7ed-42f0-a1f6-e1bfeefcf6e5": {
        text: "general.scenes.duo_B.guide.1.iframe2",
      },
    },
    3: {
      iframe: true,
      "3d52f1b5-adc3-4fd6-ae58-39cebd8ab216": {
        text: "general.scenes.duo_B.guide.3.iframe1",
      },
      "91d3ff65-c30c-4266-ad87-d594eef822b1": {
        text: "general.scenes.duo_B.guide.3.iframe2",
      },
      "b6c666d3-35ce-4a59-aebb-718bda0acc1b": {
        text: "general.scenes.duo_B.guide.3.iframe3",
      },
    },
    4: {
      iframe: true,
      "330ecc54-741d-473b-b913-5689c3f844ae": {
        text: "general.scenes.duo_B.guide.4.iframe1",
      },
      "1f178b97-c4b6-449a-b8de-17984d8fa4e7": {
        text: "general.scenes.duo_B.guide.4.iframe2",
      },
    },
  },
  duo_C: {
    0: {
      text: "general.scenes.duo_C.guide.0",
    },
    1: {
      text: "general.scenes.duo_C.guide.1",
      //   maxWidth: "400px",
    },
    2: {
      iframe: true,
      "ce74ea62-6fa0-4bff-b7f4-adba11f37d3e": {
        text: "general.scenes.duo_C.guide.2.iframe1",
      },
    },
    4: {
      iframe: true,
      "2c331878-8067-4197-8d22-5aa5803fbea8": {
        text: "general.scenes.duo_C.guide.4.iframe1",
      },
      "8ee8e19a-4d6d-41bf-bd8c-12062e85cbfd": {
        text: "general.scenes.duo_C.guide.4.iframe2",
      },
    },
    5: {
      iframe: true,
      "a4123a46-5feb-4a27-bc86-11a664fd4932": {
        text: "general.scenes.duo_C.guide.5.iframe1",
      },
    },
    6: {
      iframe: true,
      "fbddf626-d9d1-426e-a770-ea734940c413": {
        text: "general.scenes.duo_C.guide.6.iframe1",
      },
    },
  },
  surfaceLaptopGo_A: {
    0: {
      text: "general.scenes.surfaceLaptopGo_A.guide.0",
    },
    1: {
      iframe: true,
      firstScreen: {
        //"65b98250-51db-4f03-acf4-9cfc07ebf2a1": {
        text: "general.scenes.surfaceLaptopGo_A.guide.1.iframe1",
      },
      "5dfe36e5-1054-4355-9bd3-830e601f5005": {
        text: "general.scenes.surfaceLaptopGo_A.guide.1.iframe2",
      },
    },
    2: {
      iframe: true,
      firstScreen: {
        //"a4ad5169-67a7-45fd-93f1-2516c478060b": {
        text: "general.scenes.surfaceLaptopGo_A.guide.2.iframe1",
      },
      "77ae2153-208e-484d-bd64-d41d87c12eb8": {
        text: "general.scenes.surfaceLaptopGo_A.guide.2.iframe2",
      },
      "fa23b0e2-2463-457f-a725-2dbf987b76ce": {
        text: "general.scenes.surfaceLaptopGo_A.guide.2.iframe3",
      },
      "4c797f87-d813-4839-8522-a82e9ceaca63": {
        text: "general.scenes.surfaceLaptopGo_A.guide.2.iframe4",
      },
    },
  },
  surfaceLaptopGo_B: {
    0: {
      text: "general.scenes.surfaceLaptopGo_B.guide.0",
    },
  },
};
