import { createStore } from "vuex";

export const store = createStore({
  state: {
    quality: "md",
    qualityLevel: {
      sd: 1.5,
      md: 1,
      hd: 0.5,
    },
    qualityLevelLimit: {
      sd: 1.25,
      md: 1,
      hd: 0.75,
    },
    activeDevices: {
      spro9: true,
      spro10: true,
      spro11: true,
      spro8: true,
      sprox: true,
      sgo3: true,
      // New change for 9/21 ----
      sgo4: true,
      // ----
      slaptop7: true,
      slaptop5: true,
      slaptop6: true,
      slaptop4: true,
      // New change for 9/21 ----
      slaptops2: true,
      // ----
      slaptops: true,
      // New change for 9/21 ----
      slaptopgo3: true,
      // ----
      slaptopgo: true,
      sduo2: true,
      sstudio2: true,
      shub385: true,
      shub350: true,
      shub2s: true,
      shubs: true,
      shead2: true,
      sproFlexKeyboard: true,
      sear: true,
    },
    focusedDevice: null,
    shareUrl: "",
    isFamilyExplorer: false,
    isFeedBackOpen: false,
    pageIndex: 0,
    pageIndexTotal: 0,
    actualLang: "",
    avoidDoubleClick: false,
    meshOverlay: {
      items: {},
      itemsPos: {},
    },
    meshOverlayHidden: false,
    isMenuOpen: null,
    isFilterOpen: false,
    loaderIsShowing: true,
    loadedPercent: 0,
    tooltip: "",
    isEndingSidebarTransitioning: false,
    surfaceLaptop_A_SwipeID: 0,
    famExpBreadcrumbs: [],
    camDeg: 160,
    camBeta: 1.3,
    showDev: false,
    currentTooltip: "",
    famExpCurrentPosture: "laptop",
    famExpCurrentModel: "",
    famExpDeviceLoading: "",
    famExpDeviceLoadingLabel: "",
    IFrameStep: 0,
    currentScenario: {
      navDirection: "forward",
      guideId: "",
      guideIsShowing: true,
      activeIframeData: {
        title: "",
        sectionId: null,
        screenId: null,
      },
      iFrameIndexes: {},
    },
    windowSize: {
      w: 0,
      h: 0,
      aspectRatio: 0,
      readableRatio: "wide",
    },
    currentBrowser: null,
  },
  getters: {
    pageIndex: state => {
      return state.pageIndex;
    },
    actualLang: state => {
      return state.actualLang;
    },
    avoidDoubleClick: state => {
      return state.avoidDoubleClick;
    },
    IFrameStep: state => {
      return state.IFrameStep;
    },
    isFeedBackOpen: state => {
      return state.isFeedBackOpen;
    },
    currentSlideNumber: state => {
      let curSlide = state.pageIndex + 1;
      Object.keys(state.currentScenario.iFrameIndexes).forEach(key => {
        Object.keys(state.currentScenario.iFrameIndexes[key]).forEach(
          iframeData => {
            curSlide +=
              state.currentScenario.iFrameIndexes?.[key]?.[iframeData]
                .currentIndex;
          }
        );
      });
      return curSlide;
    },
    totalSlides: state => {
      let total = state.pageIndexTotal;
      Object.keys(state.currentScenario.iFrameIndexes).forEach(key => {
        Object.keys(state.currentScenario.iFrameIndexes[key]).forEach(
          iframeData => {
            total +=
              state.currentScenario.iFrameIndexes?.[key]?.[iframeData]
                .screenCount - 1;
          }
        );
      });
      return total;
    },
  },
  mutations: {
    updateDevices(state, data) {
      if (data) {
        state.activeDevices[data.id] = data.value;
      }
      let keys = Object.keys(state.activeDevices).filter(
        k => state.activeDevices[k] === true
      );
      state.shareUrl = `${window.location.origin}/#/?filter=${keys.join()}`;
    },
    setDevices(state, devices) {
      Object.keys(state.activeDevices).forEach(key => {
        if (devices.includes(key)) {
          state.activeDevices[key] = true;
        } else {
          state.activeDevices[key] = false;
        }
      });
    },
    updateShowDev(state, bool) {
      state.showDev = bool;
    },
    updateActualLang(state, string) {
      state.actualLang = string;
    },
    updateIFrameStep(state, index) {
      state.IFrameStep = index;
    },
    updateiFrameIndexesOnLoad(state, data) {
      if (!state.currentScenario.iFrameIndexes[data.title]) {
        state.currentScenario.iFrameIndexes[data.title] = {};
      }
      state.currentScenario.iFrameIndexes[data.title][data.index] = {
        screenCount: data.section.screenCount,
        currentIndex: 0,
      };
    },
    resetiFrameIndexes(state) {
      state.currentScenario.iFrameIndexes = {};
    },
    updateiFrameIndexesOnNav(state, data) {
      state.currentScenario.iFrameIndexes[data.title][
        data.sectionIndex
      ].currentIndex = data.screenIndex;
      state.currentScenario.activeIframeData.screenId = data.screenId;
    },
    resetActiveIframeData(state) {
      state.currentScenario.activeIframeData = {
        title: "",
        sectionId: null,
        screenId: null,
      };
    },
    updateActiveIframeData(state, data) {
      const { title, sectionId, screenId } = data;
      state.currentScenario.activeIframeData = { title, sectionId, screenId };
    },
    updatePageIndexTotal(state, total) {
      state.pageIndexTotal = total;
    },
    reset(state) {
      state.pageIndex = 0;
      state.meshOverlay.items = {};
    },
    next(state) {
      state.pageIndex++;
    },
    prev(state) {
      state.pageIndex--;
    },
    updateCurScenarioGuideShowing(state, bool) {
      state.currentScenario.guideIsShowing = bool;
    },
    updateCurScenarioGuideId(state, id) {
      state.currentScenario.guideId = id;
    },
    updateIsFamilyExplorer(state, bool) {
      state.isFamilyExplorer = bool;
    },
    updateAvoidDoubleClick(state, bool) {
      state.avoidDoubleClick = bool;
    },
    meshOverlayUpdateItemsPos(state, itemPositions) {
      state.meshOverlay.itemsPos = itemPositions;
    },
    meshOverlayUpdateItem(state, payload) {
      const key = payload.id;
      const { position, type, data } = payload;
      state.meshOverlay.items[key] = {
        id: key,
        type,
        position,
        data,
        ...state.meshOverlay.items[key],
      };
    },
    meshOverlayUpdateItems(state, items) {
      state.meshOverlay.items = items;
    },
    meshOverlayResetItems(state) {
      state.meshOverlay.items = {};
    },
    meshOverlaySelectItem(state, payload) {
      const key = payload.id;
      if (state.tooltip === key) {
        state.tooltip = "";
      } else {
        state.tooltip = key;
      }
    },
    updateIsMenuOpen(state, bool) {
      state.isMenuOpen = bool;
    },
    updateFilterOpen(state, bool) {
      state.isFilterOpen = bool;
    },
    updateLoaderIsShowing(state, bool) {
      state.loaderIsShowing = bool;
    },
    updateIsEndingSidebarTransitioning(state, bool) {
      state.isEndingSidebarTransitioning = bool;
    },
    updateSurfaceLaptop_A_SwipeID(state, num) {
      state.surfaceLaptop_A_SwipeID = num;
    },
    addFamExpBreadcrumb(state, tooltipId) {
      if (!state.famExpBreadcrumbs.includes(tooltipId)) {
        state.famExpBreadcrumbs.push(tooltipId);
      }
    },
    resetFamExpBreadcrumbs(state) {
      state.famExpBreadcrumbs = [];
    },
    updateCam(state, cam) {
      state.camDeg = cam.deg;
      state.camBeta = cam.beta;
    },
    updateCurrentTooltip(state, tooltipId) {
      state.currentTooltip = tooltipId;
      if (!state.famExpBreadcrumbs.includes(tooltipId)) {
        state.famExpBreadcrumbs.push(tooltipId);
      }
    },
    updateFamExpCurrentPosture(state, posture) {
      state.famExpCurrentPosture = posture;
    },
    updateFamExpCurrentModel(state, model) {
      state.famExpCurrentModel = model;
    },
    updateFamExpDeviceLoading(state, deviceName) {
      state.famExpDeviceLoading = deviceName;
    },
    updateFamExpDeviceLoadingLabel(state, deviceName) {
      state.famExpDeviceLoadingLabel = deviceName;
    },
    setQuality(state, quality) {
      state.quality = quality;
    },
    updateMeshOverlayHidden(state, bool) {
      state.meshOverlayHidden = bool;
    },
    windowSize(state, obj) {
      state.windowSize = obj;
    },
    updateFeedBack(state, open) {
      state.isFeedBackOpen = open;
    },
    updateCurrentBrowser(state, browserName) {
      state.currentBrowser = browserName;
    },
    updateFocusedDevice(state, deviceCode) {
      if (deviceCode) {
        state.focusedDevice = deviceCode;
      } else {
        state.focusedDevice = null;
      }
    },
    updateCurrentNavDirection(state, str) {
      state.currentScenario.navDirection = str;
    },
  },
  actions: {
    updateCurrentTooltip({ commit }, tooltipId) {
      commit("updateCurrentTooltip", tooltipId);
      commit("addFamExpBreadcrumb", tooltipId);
    },
    next({ commit }) {
      commit("next");
      // saving for deeper navigation, add direction as param
      //   commit("updateCurrentNavDirection", direction ? direction : "forward");
    },
    prev({ commit }) {
      commit("prev");
      // saving for deeper navigation, add direction as param
      //   console.log(direction);
      //   commit("updateCurrentNavDirection", direction ? direction : "back");
    },
    windowSize({ commit }) {
      let windowSizeObj = {};
      windowSizeObj.w = window.innerWidth;
      windowSizeObj.h = window.innerHeight;
      windowSizeObj.aspectRatio = windowSizeObj.w / windowSizeObj.h;
      if (windowSizeObj.aspectRatio > 0) {
        if (windowSizeObj.aspectRatio < 1.7) {
          windowSizeObj.readableRatio = "standard";
        } else if (
          windowSizeObj.aspectRatio >= 1.7 &&
          windowSizeObj.aspectRatio < 2.1
        ) {
          windowSizeObj.readableRatio = "wide";
        } else if (windowSizeObj.aspectRatio >= 2.1) {
          windowSizeObj.readableRatio = "ultrawide";
        }
      }
      commit("windowSize", windowSizeObj);
    },
    updateCurrentBrowser({ commit }) {
      let browserName = "";
      if (navigator.vendor.match(/google/i)) {
        browserName = "chrome/blink";
      } else if (navigator.vendor.match(/apple/i)) {
        browserName = "safari/webkit";
      } else if (navigator.userAgent.match(/firefox\//i)) {
        browserName = "firefox/gecko";
      } else if (navigator.userAgent.match(/edge\//i)) {
        browserName = "edge/edgehtml";
      } else if (navigator.userAgent.match(/trident\//i)) {
        browserName = "ie/trident";
      } else {
        browserName = navigator.userAgent + "\n" + navigator.vendor;
      }
      commit("updateCurrentBrowser", browserName);
    },
  },
});
