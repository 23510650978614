<template>
  <FocusTrap ref="focusTrapRef" :intial-focus="() => $refs.menuCloseBtn">
    <div class="menu" :class="{ 'menu--chaningRoutes': changingRoutes }">
      <!-- v-if="route.name !== 'Home'" -->
      <button
        :class="{
          'menu__button--hidden':
            isEndingSidebarTransitioning || loaderIsShowing,
        }"
        class="menu__button"
        @click="$store.commit('updateIsMenuOpen', true)"
        :aria-label="$t('accessibility.openMenu')"
        :tabindex="$store.state.isMenuOpen ? -1 : 0"
      >
        <div v-for="i in 3" :key="`line${i}`"></div>
      </button>
      <transition name="menu-slideout">
        <div v-show="menuIsOpen" class="menu__content">
          <button
            class="menu__close"
            @click="$store.commit('updateIsMenuOpen', false)"
            :aria-label="$t('accessibility.closeMenu')"
            tabindex="0"
            ref="menuCloseBtn"
          >
            <img
              src="@/assets/close-x.svg"
              v-bind:alt="$t('accessibility.closeMenu')"
            />
          </button>
          <div class="home-btn">
            <MenuLink
              :link="homeRoute"
              :current-path="route.path"
              aria=""
              @close-menu="closeMenu"
            >
              {{ $t(homeRoute.displayName) }}
            </MenuLink>
          </div>
          <div class="menu__content-inner custom-scrollbar">
            <div
              v-for="(category, categoryIndex) in menuRoutes"
              :key="`category${categoryIndex}`"
              style="width: 100%"
            >
              <MenuCollapse
                :isOpen="selectedCategory === category.name"
                :item="category"
                :index="categoryIndex"
                @button-click="
                  val => {
                    selectedCategory == val
                      ? (selectedCategory = null)
                      : (selectedCategory = val);
                    selectedDevice = null;
                  }
                "
                :aria="
                  $t('accessibility.openDeviceMenu', {
                    device: $t(category.displayName),
                  })
                "
                :padBottom="!category.hasNestedCollapse"
              >
                <div v-if="category.children">
                  <div
                    v-for="(device, deviceIndex) in category.children"
                    :key="`device${deviceIndex}`"
                  >
                    <MenuLink
                      v-if="device.path"
                      :link="device"
                      :current-path="route.path"
                      @close-menu="closeMenu"
                      :parent="device"
                      :aria="
                        category.comingSoon
                          ? 'Coming soon'
                          : $t(device.aria, {
                              device: $t(category.displayName),
                              scenario: $t(device.displayName),
                            })
                      "
                      :animateIn="!menuIsOpening"
                      :isExternal="device.isExternal"
                      :isNewLink="device.isNewLink"
                    >
                      {{ $t(device.displayName) }}
                    </MenuLink>

                    <MenuCollapse
                      v-else
                      :isOpen="selectedDevice === device.name"
                      :item="device"
                      :index="deviceIndex"
                      :isEnabled="device.deviceEnabled"
                      @button-click="
                        val => {
                          selectedDevice == val
                            ? (selectedDevice = null)
                            : (selectedDevice = val);
                        }
                      "
                      :aria="
                        $t('accessibility.openDeviceMenu', {
                          device: $t(device.displayName),
                        })
                      "
                      :animateIn="!menuIsOpening"
                      :padBottom="!device.hasNestedCollapse"
                      isNested
                      :isLast="deviceIndex === category.children.length - 1"
                    >
                      <div v-if="device.children">
                        <div
                          v-for="(link, linkIndex) in device.children"
                          :key="`link${linkIndex}`"
                        >
                          <MenuLink
                            v-if="link.path"
                            :link="link"
                            :current-path="route.path"
                            :aria="
                              device.comingSoon
                                ? 'Coming soon'
                                : $t(link.aria, {
                                    device: $t(device.displayName),
                                    scenario: $t(link.displayName),
                                  })
                            "
                            @close-menu="closeMenu"
                            :parent="device"
                            :animateIn="!menuIsOpening"
                            :isExternal="device.isExternal"
                          >
                            {{
                              device.comingSoon
                                ? "Coming soon"
                                : $t(link.displayName)
                            }}
                          </MenuLink>
                        </div>
                      </div>
                    </MenuCollapse>
                  </div>
                </div>
              </MenuCollapse>
            </div>
            <div style="width: 100%">
              <MenuCollapse
                :isOpen="selectedCategory === calcRoute.name"
                :item="calcRoute"
                :padBottom="!calcRoute.hasNestedCollapse"
                @button-click="
                  val => {
                    selectedCategory == val
                      ? (selectedCategory = null)
                      : (selectedCategory = val);
                    selectedDevice = null;
                  }
                "
              >
                <div
                  v-for="(link, linkIndex) in calcRoute.children"
                  :key="`link${linkIndex}`"
                >
                  <MenuLink isExternal :link="link" :animateIn="!menuIsOpening">
                    {{ link.displayName }}
                  </MenuLink>
                </div>
              </MenuCollapse>
            </div>
          </div>

          <StarRating class="anim_start_rating" />
        </div>
      </transition>
      <div class="menu__fade"></div>
    </div>
  </FocusTrap>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { store } from "@/data/store.js";
import anime from "animejs";
import { FocusTrap } from "focus-trap-vue";
import StarRating from "@/components/StarRating.vue";
import useEmitter from "@/js/composables/useEmitter";
import MenuLink from "@/components/common/MenuLink";
import MenuCollapse from "@/components/common/MenuCollapse";

export default {
  components: {
    FocusTrap,
    StarRating,
    MenuLink,
    MenuCollapse,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const emitter = useEmitter();
    const selectedCategory = ref("");
    const selectedDevice = ref(null);
    const selectedDeviceLabel = ref(null);
    const menuIsOpening = ref(false);
    const changingRoutes = ref(false);
    const stars = ref([1, 2, 3, 4, 5]);
    const star_selected = ref(localStorage.getItem("starRating") || 0);
    const star_hover = ref(0);
    const focusTrapRef = ref(null);

    const homeRoute = ref({
      name: "Home",
      displayName: "general.menu.home",
      path: `/${store.state.actualLang}`,
      familyExplorer: true,
    });
    const calcRoute = ref({
      name: "Calculators",
      displayName: "Calculators",
      path: null,
      children: [
        {
          path: `${process.env.VUE_APP_TCO_CALCULATOR_URL}`,
          name: "TCO",
          displayName: "Total Cost of Ownership (TCO) Calculator",
          aria: "TCO",
          isNewLink: true,
        },
        {
          path: `${process.env.VUE_APP_SUS_ESTIMATOR_URL}`,
          name: "SEE",
          displayName: "Emissions Estimator (Sustainability)",
          aria: "SEE",
          isNewLink: true,
        },
      ],
    });
    const menuRoutes = ref([
      {
        name: "2-in-1 Tablets",
        displayName: "general.menu.category.twoInOneTablets",
        path: null,
        // comingSoon: true,
        hasNestedCollapse: true,
        children: [
          {
            path: `/${store.state.actualLang}/device/pro11`,
            name: "Surface Pro",
            aria: "accessibility.deviceExplorer",
            displayName: "general.menu.pro11.menuName",
            deviceEnabled: "spro11",
            familyExplorer: true,
            isNewLink: true,
          },
          {
            path: `/${store.state.actualLang}/device/pro10`,
            name: "Surface Pro 10",
            aria: "accessibility.deviceExplorer",
            displayName: "general.menu.pro10.name",
            deviceEnabled: "spro10",
            familyExplorer: true,
            isNewLink: true,
          },
          {
            path: `/${store.state.actualLang}/device/pro9`,
            name: "Surface Pro 9",
            aria: "accessibility.deviceExplorer",
            displayName: "general.menu.pro9.name",
            deviceEnabled: "spro9",
            familyExplorer: true,
            isNewLink: true,
          },
          {
            path: `/${store.state.actualLang}/device/go4`,
            name: "Surface Go 4",
            aria: "accessibility.deviceExplorer",
            displayName: "general.menu.go4.name",
            deviceEnabled: "sgo4",
            familyExplorer: true,
            isNewLink: true,
          },
          // {
          //   path: `/${store.state.actualLang}/device/go3`,
          //   name: "Surface Go 3",
          //   aria: "accessibility.deviceExplorer",
          //   displayName: "general.menu.go3.name",
          //   deviceEnabled: "sgo3",
          //   familyExplorer: true,
          //   isNewLink: true,
          // },
          // {
          //   name: "Surface Pro 9",
          //   displayName: "general.menu.pro9.name",
          //   path: null,
          //   deviceEnabled: "spro9",
          //   children: [
          //     {
          //       path: `/${store.state.actualLang}/device/pro9`,
          //       name: "Explore this device",
          //       aria: "accessibility.deviceExplorer",
          //       displayName: "general.menu.explore",
          //       familyExplorer: true,
          //     },
          //     {
          //       path: `/${store.state.actualLang}/productivity-with-natural-inputs`,
          //       name: "Surface Pro 9",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.pro9.productivityNaturalInputs",
          //     },
          //     {
          //       path: `/${store.state.actualLang}/stay-connected-with-5G`,
          //       name: "Surface Pro 9",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.pro9.stayConnected",
          //     },
          //     {
          //       path: `/${store.state.actualLang}/performance-by-your-definition`,
          //       name: "Surface Pro 9",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.pro9.performanceByYourDef",
          //     },
          //   ],
          // },
          // // New change for 9/21 ----
          // // {
          // //   name: "Surface Pro 8",
          // //   displayName: "general.menu.pro8.name",
          // //   path: null,
          // //   deviceEnabled: "spro8",
          // //   children: [
          // //     {
          // //       path: `/${store.state.actualLang}/device/pro8`,
          // //       name: "Explore this device",
          // //       aria: "accessibility.deviceExplorer",
          // //       displayName: "general.menu.explore",
          // //       familyExplorer: true,
          // //     },
          // //   ],
          // // },

          // // {
          // //   name: "Surface Pro X",
          // //   displayName: "general.menu.proX.name",
          // //   path: null,
          // //   deviceEnabled: "sprox",
          // //   // comingSoon: true,
          // //   children: [
          // //     {
          // //       path: `/${store.state.actualLang}/device/proX`,
          // //       name: "Explore this device",
          // //       aria: "accessibility.deviceExplorer",
          // //       displayName: "general.menu.explore",
          // //       familyExplorer: true,
          // //     },
          // //     // {
          // //     //   path: `/${store.state.actualLang}/stay-connected`,
          // //     //   name: "Surface Pro X",
          // //     //   aria: "accessibility.scenarioExplorer",
          // //     //   displayName: "general.menu.proX.stayConnected",
          // //     // },
          // //   ],
          // // },
          // // ----
          // // New change for 9/21 ----
          // {
          //   name: "Surface Go 4",
          //   displayName: "general.menu.go4.name",
          //   path: null,
          //   deviceEnabled: "sgo4",
          //   // comingSoon: true,
          //   children: [
          //     {
          //       path: `/${store.state.actualLang}/device/go4`,
          //       name: "Explore this device",
          //       aria: "accessibility.deviceExplorer",
          //       displayName: "general.menu.explore",
          //       familyExplorer: true,
          //     },
          //     // {
          //     //   path: `/${store.state.actualLang}/productivity-and-inking`,
          //     //   name: "Productivity and inking",
          //     //   aria: "accessibility.scenarioExplorer",
          //     //   displayName: "general.menu.go3.actionNatural",
          //     // },
          //     // {
          //     //   path: `/${store.state.actualLang}/maps-on-the-go`,
          //     //   name: "Maps on the go",
          //     //   aria: "accessibility.scenarioExplorer",
          //     //   displayName: "general.menu.go3.actionMaps",
          //     // },
          //     // {
          //     //   path: `/${store.state.actualLang}/taking-a-picture`,
          //     //   name: "Taking a picture",
          //     //   aria: "accessibility.scenarioExplorer",
          //     //   displayName: "general.menu.go3.actionPicture",
          //     // },
          //   ],
          // },
          // // ----
          // {
          //   name: "Surface Go 3",
          //   displayName: "general.menu.go3.name",
          //   path: null,
          //   deviceEnabled: "sgo3",
          //   // comingSoon: true,
          //   children: [
          //     {
          //       path: `/${store.state.actualLang}/device/go3`,
          //       name: "Explore this device",
          //       aria: "accessibility.deviceExplorer",
          //       displayName: "general.menu.explore",
          //       familyExplorer: true,
          //     },
          //     {
          //       path: `/${store.state.actualLang}/productivity-and-inking`,
          //       name: "Productivity and inking",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.go3.actionNatural",
          //     },
          //     {
          //       path: `/${store.state.actualLang}/maps-on-the-go`,
          //       name: "Maps on the go",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.go3.actionMaps",
          //     },
          //     {
          //       path: `/${store.state.actualLang}/taking-a-picture`,
          //       name: "Taking a picture",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.go3.actionPicture",
          //     },
          //   ],
          // },
        ],
      },
      {
        name: "Laptops",
        displayName: "general.menu.category.laptops",
        path: null,
        // comingSoon: true,
        hasNestedCollapse: true,
        children: [
          {
            path: `/${store.state.actualLang}/device/laptop7`,
            name: "Surface Laptop",
            aria: "accessibility.deviceExplorer",
            displayName: "general.menu.laptop7.menuName",
            deviceEnabled: "slaptop7",
            familyExplorer: true,
            isNewLink: true,
          },
          {
            path: `/${store.state.actualLang}/device/laptop6`,
            name: "Surface Laptop 6",
            aria: "accessibility.deviceExplorer",
            displayName: "general.menu.laptop6.name",
            deviceEnabled: "slaptop6",
            familyExplorer: true,
            isNewLink: true,
          },
          {
            path: `/${store.state.actualLang}/device/laptop5`,
            name: "Surface Laptop 5",
            aria: "accessibility.deviceExplorer",
            displayName: "general.menu.laptop5.name",
            deviceEnabled: "slaptop5",
            familyExplorer: true,
            isNewLink: true,
          },
          // {
          //   path: `/${store.state.actualLang}/device/laptop4`,
          //   name: "Surface Laptop 4",
          //   aria: "accessibility.deviceExplorer",
          //   displayName: "general.menu.laptop4.name",
          //   deviceEnabled: "slaptop4",
          //   familyExplorer: true,
          //   isNewLink: true,
          // },
          {
            path: `/${store.state.actualLang}/device/laptopStudio2`,
            name: "Surface Laptop Studio 2",
            aria: "accessibility.deviceExplorer",
            displayName: "general.menu.laptopStudio2.name",
            deviceEnabled: "slaptops2",
            familyExplorer: true,
            isNewLink: true,
          },
          // {
          //   path: `/${store.state.actualLang}/device/laptopStudio`,
          //   name: "Surface Laptop Studio",
          //   aria: "accessibility.deviceExplorer",
          //   displayName: "general.menu.laptopStudio.name",
          //   deviceEnabled: "slaptops",
          //   familyExplorer: true,
          //   isNewLink: true,
          // },
          {
            path: `/${store.state.actualLang}/device/laptopGo3`,
            name: "Surface Laptop Go 3",
            aria: "accessibility.deviceExplorer",
            displayName: "general.menu.laptopGo3.name",
            deviceEnabled: "slaptopgo3",
            familyExplorer: true,
            isNewLink: true,
          },
          // {
          //   path: `/${store.state.actualLang}/device/laptopGo2`,
          //   name: "Surface Laptop Go 2",
          //   aria: "accessibility.deviceExplorer",
          //   displayName: "general.menu.laptopGo.name",
          //   deviceEnabled: "slaptopgo",
          //   familyExplorer: true,
          //   isNewLink: true,
          // },
          // {
          //   name: "Surface Laptop 5",
          //   displayName: "general.menu.laptop5.name",
          //   path: null,
          //   deviceEnabled: "slaptop5",
          //   // comingSoon: true,
          //   // minHeight: "136px",
          //   children: [
          //     {
          //       path: `/${store.state.actualLang}/device/laptop5`,
          //       name: "Explore this device",
          //       aria: "accessibility.deviceExplorer",
          //       displayName: "general.menu.explore",
          //       familyExplorer: true,
          //     },
          //     // {
          //     //   path: `/${store.state.actualLang}/lightweight-performance`,
          //     //   name: "Lightweight performance",
          //     //   aria: "accessibility.scenarioExplorer",
          //     //   displayName: "general.menu.laptop4.actionLightweight",
          //     // },
          //     // {
          //     //   path: `/${store.state.actualLang}/responsive-trackpad`,
          //     //   name: "Responsive trackpad",
          //     //   aria: "accessibility.scenarioExplorer",
          //     //   displayName: "general.menu.laptop4.actionTrackpad",
          //     // },
          //     // {
          //     //   path: `/${store.state.actualLang}/best-in-class-meetings`,
          //     //   name: "Best-in-class meeting",
          //     //   aria: "accessibility.scenarioExplorer",
          //     //   displayName: "general.menu.laptop4.actionMeetings",
          //     // },
          //   ],
          // },
          // {
          //   name: "Surface Laptop 4",
          //   displayName: "general.menu.laptop4.name",
          //   path: null,
          //   deviceEnabled: "slaptop4",
          //   // comingSoon: true,
          //   // minHeight: "136px",
          //   children: [
          //     {
          //       path: `/${store.state.actualLang}/device/laptop4`,
          //       name: "Explore this device",
          //       aria: "accessibility.deviceExplorer",
          //       displayName: "general.menu.explore",
          //       familyExplorer: true,
          //     },
          //     {
          //       path: `/${store.state.actualLang}/lightweight-performance`,
          //       name: "Lightweight performance",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.laptop4.actionLightweight",
          //     },
          //     {
          //       path: `/${store.state.actualLang}/responsive-trackpad`,
          //       name: "Responsive trackpad",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.laptop4.actionTrackpad",
          //     },
          //     {
          //       path: `/${store.state.actualLang}/best-in-class-meetings`,
          //       name: "Best-in-class meeting",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.laptop4.actionMeetings",
          //     },
          //   ],
          // },
          // // New change for 9/21 ----
          // {
          //   name: "Surface Laptop Studio 2",
          //   displayName: "general.menu.laptopStudio2.name",
          //   path: null,
          //   deviceEnabled: "slaptops2",
          //   // comingSoon: true,
          //   children: [
          //     {
          //       path: `/${store.state.actualLang}/device/laptopStudio2`,
          //       name: "Explore this device",
          //       aria: "accessibility.deviceExplorer",
          //       displayName: "general.menu.explore",
          //       familyExplorer: true,
          //     },
          //   ],
          // },
          // // ----
          // {
          //   name: "Surface Laptop Studio",
          //   displayName: "general.menu.laptopStudio.name",
          //   path: null,
          //   deviceEnabled: "slaptops",
          //   // comingSoon: true,
          //   children: [
          //     {
          //       path: `/${store.state.actualLang}/device/laptopStudio`,
          //       name: "Explore this device",
          //       aria: "accessibility.deviceExplorer",
          //       displayName: "general.menu.explore",
          //       familyExplorer: true,
          //     },
          //     {
          //       path: `/${store.state.actualLang}/ideate-with-microsoft-whiteboard`,
          //       name: "Ideate with Microsoft Whiteboard",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.laptopStudio.ideateWithWiteboard",
          //     },
          //     {
          //       path: `/${store.state.actualLang}/create-dynamic-presentations-with-powerPoint`,
          //       name: "Create dynamic presentations with PowerPoint",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.laptopStudio.createDynamicPPT",
          //       twoLines: true,
          //     },
          //   ],
          // },
          // // New change for 9/21 ----
          // {
          //   name: "Surface Laptop Go 3",
          //   displayName: "general.menu.laptopGo3.name",
          //   path: null,
          //   deviceEnabled: "slaptopgo3",
          //   // comingSoon: true,
          //   children: [
          //     {
          //       path: `/${store.state.actualLang}/device/laptopGo3`,
          //       name: "Explore this device",
          //       aria: "accessibility.deviceExplorer",
          //       displayName: "general.menu.explore",
          //       familyExplorer: true,
          //     },
          //   ],
          // },
          // // ----
          // {
          //   name: "Surface Laptop Go 2",
          //   displayName: "general.menu.laptopGo.name",
          //   path: null,
          //   deviceEnabled: "slaptopgo",
          //   // comingSoon: true,
          //   children: [
          //     {
          //       path: `/${store.state.actualLang}/device/laptopGo2`,
          //       name: "Explore this device",
          //       aria: "accessibility.deviceExplorer",
          //       displayName: "general.menu.explore",
          //       familyExplorer: true,
          //     },
          //     {
          //       path: `/${store.state.actualLang}/touchscreen-display`,
          //       name: "Touchscreen display",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.laptopGo.actionTouchscreen",
          //     },
          //     {
          //       path: `/${store.state.actualLang}/desktop-on-the-go`,
          //       name: "Desktop on the go",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.laptopGo.actionDesktop",
          //     },
          //   ],
          // },
        ],
      },
      // {
      //   name: "Dual Screen",
      //   displayName: "general.menu.category.dualScreen",
      //   path: null,
      //   // comingSoon: true,
      //   hasNestedCollapse: true,
      //   children: [
      //     {
      //       path: `/${store.state.actualLang}/device/duo2`,
      //       name: "Surface Duo 2",
      //       aria: "accessibility.deviceExplorer",
      //       displayName: "general.menu.duo.name",
      //       deviceEnabled: "sduo2",
      //       familyExplorer: true,
      //       isNewLink: true,
      //     },
      //     // {
      //     //   name: "Surface Duo 2",
      //     //   displayName: "general.menu.duo.name",
      //     //   path: null,
      //     //   deviceEnabled: "sduo2",
      //     //   children: [
      //     //     {
      //     //       path: `/${store.state.actualLang}/device/duo2`,
      //     //       name: "Explore this device",
      //     //       aria: "accessibility.deviceExplorer",
      //     //       displayName: "general.menu.explore",
      //     //       familyExplorer: true,
      //     //     },
      //     //     {
      //     //       path: `/${store.state.actualLang}/prepare-and-present-with-surface-duo-2`,
      //     //       aria: "accessibility.scenarioExplorer",
      //     //       name: "Surface Duo A",
      //     //       displayName: "general.menu.duo.duoA",
      //     //     },
      //     //     {
      //     //       path: `/${store.state.actualLang}/do-more-with-outlook-on-surface-duo-2`,
      //     //       name: "Surface Duo B",
      //     //       aria: "accessibility.scenarioExplorer",
      //     //       displayName: "general.menu.duo.duoB",
      //     //     },
      //     //     {
      //     //       path: `/${store.state.actualLang}/stay-in-the-know-even-when-its-closed`,
      //     //       name: "Surface Duo C",
      //     //       aria: "accessibility.scenarioExplorer",
      //     //       displayName: "general.menu.duo.duoC",
      //     //     },
      //     //   ],
      //     // },
      //   ],
      // },
      {
        name: "Large Screens",
        displayName: "general.menu.category.largeScreens",
        path: null,
        // comingSoon: true,
        hasNestedCollapse: true,
        children: [
          {
            path: `/${store.state.actualLang}/device/studio2plus`,
            name: "Surface Studio 2+",
            aria: "accessibility.deviceExplorer",
            displayName: "general.menu.studio2.name",
            deviceEnabled: "sstudio2",
            familyExplorer: true,
            isNewLink: true,
          },
          {
            path: `/${store.state.actualLang}/device/hub3`,
            name: "Surface Hub 3",
            aria: "accessibility.deviceExplorer",
            displayName: "general.menu.hubs3.name",
            deviceEnabled: "shub385",
            familyExplorer: true,
            isNewLink: true,
          },
          {
            path: `/${store.state.actualLang}/device/hub2s`,
            name: "Surface Hub 2S",
            aria: "accessibility.deviceExplorer",
            displayName: "general.menu.hubs.name",
            deviceEnabled: "shub2s",
            familyExplorer: true,
            isNewLink: true,
          },
          // {
          //   name: "Surface Studio 2+",
          //   displayName: "general.menu.studio2.name",
          //   path: null,
          //   deviceEnabled: "sstudio2",
          //   // comingSoon: true,
          //   children: [
          //     {
          //       path: `/${store.state.actualLang}/device/studio2plus`,
          //       name: "Explore this device",
          //       aria: "accessibility.deviceExplorer",
          //       displayName: "general.menu.explore",
          //       familyExplorer: true,
          //     },
          //     {
          //       path: `/${store.state.actualLang}/creative-freedom`,
          //       name: "Creative freedom",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.studio2.actionFreedom",
          //     },
          //     {
          //       path: `/${store.state.actualLang}/color-accuracy`,
          //       name: "Color accuracy",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.studio2.actionAccuracy",
          //     },
          //   ],
          // },
          // // New change for 9/21 ----
          // {
          //   name: "Surface Hub 3",
          //   displayName: "general.menu.hubs3.name",
          //   path: null,
          //   deviceEnabled: "shub385",
          //   children: [
          //     {
          //       path: `/${store.state.actualLang}/device/hub3`,
          //       name: "Explore this device",
          //       aria: "accessibility.deviceExplorer",
          //       displayName: "general.menu.explore",
          //       familyExplorer: true,
          //     },
          //   ],
          // },
          // // ----
          // {
          //   name: "Surface Hub 2S",
          //   displayName: "general.menu.hubs.name",
          //   path: null,
          //   deviceEnabled: "shub2s",
          //   children: [
          //     {
          //       path: `/${store.state.actualLang}/device/hub2s`,
          //       name: "Explore this device",
          //       aria: "accessibility.deviceExplorer",
          //       displayName: "general.menu.explore",
          //       familyExplorer: true,
          //     },
          //     {
          //       path: `/${store.state.actualLang}/collaborate-anywhere`,
          //       name: "Collaborate anywhere",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.hubs.actionHubA",
          //     },
          //     {
          //       path: `/${store.state.actualLang}/join-meetings-seamlessly`,
          //       name: "Join meetings seamlessly",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.hubs.actionHubB",
          //     },
          //     {
          //       path: `/${store.state.actualLang}/multiscreen-meeting`,
          //       name: "Multiscreen meeting",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.hubs.actionHubC",
          //     },
          //     {
          //       path: `/${store.state.actualLang}/personal-office`,
          //       name: "Scenario D",
          //       aria: "accessibility.scenarioExplorer",
          //       displayName: "general.menu.hubs.actionHubD",
          //     },
          //   ],
          // },
        ],
      },
      {
        name: "Surface accessories",
        displayName: "general.menu.accessories.name",
        path: null,
        // comingSoon: true,
        children: [
          {
            path: `/${store.state.actualLang}/device/proFlexKeyboard`,
            name: "Explore Surface Pro Flex Keyboard",
            aria: "general.menu.accessories.actionToba",
            displayName: "general.menu.accessories.actionToba",
            familyExplorer: true,
            deviceEnabled: "sproFlexKeyboard",
            isNewLink: true,
          },
          {
            path: `/${store.state.actualLang}/device/headphones`,
            name: "Explore Surface Headphones 2+",
            aria: "general.menu.accessories.actionHeadphones",
            displayName: "general.menu.accessories.actionHeadphones",
            familyExplorer: true,
            deviceEnabled: "shead2",
            isNewLink: true,
          },
          // {
          //   path: `/${store.state.actualLang}/device/earbuds`,
          //   name: "Explore Surface Earbuds",
          //   aria: "general.menu.accessories.actionEarbuds",
          //   displayName: "general.menu.accessories.actionEarbuds",
          //   familyExplorer: true,
          //   deviceEnabled: "sear",
          //   isNewLink: true,
          // },
        ],
      },
      {
        name: "Security, management, and deployment",
        displayName: "general.menu.management.name",
        path: null,
        // comingSoon: true,
        children: [
          {
            path: `/${store.state.actualLang}/autopilot-admin-experience`,
            name: "Autopilot admin experience",
            aria: "accessibility.scenarioExplorer2",
            displayName: "general.menu.management.actionAdmin",
            isNewLink: true,
          },
          {
            path: `/${store.state.actualLang}/autopilot-user-experience`,
            name: "Autopilot user experience",
            aria: "accessibility.scenarioExplorer2",
            displayName: "general.menu.management.actionUser",
            isNewLink: true,
          },
          {
            path: `https://aka.ms/SurfaceSSMPDemo`,
            name: "Surface Management Portal Interactive Guide",
            aria: "",
            displayName: "general.menu.management.managementPortal",
            isExternal: true,
            isNewLink: true,
          },
          {
            path: `https://aka.ms/SurfaceDFCIDemo`,
            name: "Surface Management Portal Interactive Guide",
            aria: "",
            displayName: "general.menu.management.firmwareConfig",
            isExternal: true,
            isNewLink: true,
          },
        ],
      },
    ]);

    watch(
      () => route.path,
      newVal => {
        setSelectedDevice(newVal);
        store.commit("updateFamExpDeviceLoading", selectedDevice.value);
        store.commit(
          "updateFamExpDeviceLoadingLabel",
          selectedDeviceLabel.value
        );
        store.commit("resetiFrameIndexes");
        store.commit("resetActiveIframeData");
      }
    );

    const menuIsOpen = computed(() => {
      return store.state.isMenuOpen;
    });

    const loaderIsShowing = computed(() => {
      return store.state.loaderIsShowing;
    });

    const isEndingSidebarTransitioning = computed(() => {
      return store.state.isEndingSidebarTransitioning;
    });

    onMounted(() => {
      setSelectedDevice(route.path);
      focusTrapRef.value.deactivate();
    });

    function setSelectedDevice(path) {
      switch (path) {
        case `/${store.state.actualLang}`:
          selectedCategory.value = "";
          selectedDevice.value = "Surface family explorer";
          selectedDeviceLabel.value = "";
          break;
        case `/${store.state.actualLang}/device/pro11`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Pro";
          selectedDeviceLabel.value = "general.menu.pro11.name";
          break;
        case `/${store.state.actualLang}/device/pro10`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Pro 10";
          selectedDeviceLabel.value = "general.menu.pro10.name";
          break;
        case `/${store.state.actualLang}/device/pro9`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Pro 9";
          selectedDeviceLabel.value = "general.menu.pro9.name";
          break;
        case `/${store.state.actualLang}/natural-inking-with-pro9`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Pro 9";
          selectedDeviceLabel.value = "general.menu.pro9.name";
          break;
        case `/${store.state.actualLang}/performance-by-your-definition`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Pro 9";
          selectedDeviceLabel.value = "general.menu.pro9.name";
          break;
        case `/${store.state.actualLang}/stay-connected-with-5g`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Pro 9";
          selectedDeviceLabel.value = "general.menu.pro9.name";
          break;
        // case `/${store.state.actualLang}/natural-inking-with-pro8`:
        //   selectedCategory.value = "2-in-1 Tablets";
        //   selectedDevice.value = "Surface Pro 8";
        //   selectedDeviceLabel.value = "general.menu.pro8.name";
        //   break;
        // case `/${store.state.actualLang}/performance-by-your-definition`:
        //   selectedCategory.value = "2-in-1 Tablets";
        //   selectedDevice.value = "Surface Pro 8";
        //   selectedDeviceLabel.value = "general.menu.pro8.name";
        //   break;
        case `/${store.state.actualLang}/device/pro8`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Pro 8";
          selectedDeviceLabel.value = "general.menu.pro8.name";
          break;
        case `/${store.state.actualLang}/device/proX`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Pro X";
          selectedDeviceLabel.value = "general.menu.proX.name";
          break;
        // case `/${store.state.actualLang}/stay-connected-with-lte-advanced`:
        //   selectedCategory.value = "2-in-1 Tablets";
        //   selectedDevice.value = "Surface Pro 9";
        //   selectedDeviceLabel.value = "general.menu.pro9.name";
        //   break;
        case `/${store.state.actualLang}/precision-note-taking`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Pro X";
          selectedDeviceLabel.value = "general.menu.proX.name";
          break;
        // New change for 9/21 ----
        case `/${store.state.actualLang}/device/go4`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Go 4";
          selectedDeviceLabel.value = "general.menu.go4.name";
          break;
        // ----
        case `/${store.state.actualLang}/device/go3`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Go 3";
          selectedDeviceLabel.value = "general.menu.go3.name";
          break;
        case `/${store.state.actualLang}/natural-inking`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Go 3";
          selectedDeviceLabel.value = "general.menu.go3.name";
          break;
        case `/${store.state.actualLang}/maps-on-the-go`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Go 3";
          selectedDeviceLabel.value = "general.menu.go3.name";
          break;
        case `/${store.state.actualLang}/taking-a-picture`:
          selectedCategory.value = "2-in-1 Tablets";
          selectedDevice.value = "Surface Go 3";
          selectedDeviceLabel.value = "general.menu.go3.name";
          break;
        // New change for 9/21 ----
        case `/${store.state.actualLang}/device/laptopStudio2`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop Studio 2";
          selectedDeviceLabel.value = "general.menu.laptopStudio2.name";
          break;
        // ----
        case `/${store.state.actualLang}/device/laptopStudio`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop Studio";
          selectedDeviceLabel.value = "general.menu.laptopStudio.name";
          break;
        case `/${store.state.actualLang}/ideate-with-microsoft-whiteboard`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop Studio";
          selectedDeviceLabel.value = "general.menu.laptopStudio.name";
          break;
        case `/${store.state.actualLang}/create-dynamic-presentations-with-powerPoint`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop Studio";
          selectedDeviceLabel.value = "general.menu.laptopStudio.name";
          break;
        // New change for 9/21 ----
        case `/${store.state.actualLang}/device/laptopGo3`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop Go 3";
          selectedDeviceLabel.value = "general.menu.laptopGo3.name";
          break;
        // ----
        case `/${store.state.actualLang}/device/laptopGo2`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop Go 2";
          selectedDeviceLabel.value = "general.menu.laptopGo.name";
          break;
        case `/${store.state.actualLang}/touchscreen-display`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop Go 2";
          selectedDeviceLabel.value = "general.menu.laptopGo.name";
          break;
        case `/${store.state.actualLang}/desktop-on-the-go`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop Go 2";
          selectedDeviceLabel.value = "general.menu.laptopGo.name";
          break;
        case `/${store.state.actualLang}/device/laptop4`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop 4";
          selectedDeviceLabel.value = "general.menu.laptop4.name";
          break;
        case `/${store.state.actualLang}/device/laptop5`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop 5";
          selectedDeviceLabel.value = "general.menu.laptop5.name";
          break;
        case `/${store.state.actualLang}/device/laptop6`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop 6";
          selectedDeviceLabel.value = "general.menu.laptop6.name";
          break;
        case `/${store.state.actualLang}/device/laptop7`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop";
          selectedDeviceLabel.value = "general.menu.laptop7.name";
          break;
        case `/${store.state.actualLang}/lightweight-performance`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop 4";
          selectedDeviceLabel.value = "general.menu.laptop4.name";
          break;
        case `/${store.state.actualLang}/responsive-trackpad`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop 4";
          selectedDeviceLabel.value = "general.menu.laptop4.name";
          break;
        case `/${store.state.actualLang}/best-in-class-meetings`:
          selectedCategory.value = "Laptops";
          selectedDevice.value = "Surface Laptop 4";
          selectedDeviceLabel.value = "general.menu.laptop4.name";
          break;
        case `/${store.state.actualLang}/device/duo2`:
          selectedCategory.value = "Dual Screen";
          selectedDevice.value = "Surface Duo 2";
          selectedDeviceLabel.value = "general.menu.duo.name";
          break;
        case `/${store.state.actualLang}/prepare-and-present-with-surface-duo-2`:
          selectedCategory.value = "Dual Screen";
          selectedDevice.value = "Surface Duo 2";
          selectedDeviceLabel.value = "general.menu.duo.name";
          break;
        case `/${store.state.actualLang}/do-more-with-outlook-on-surface-duo-2`:
          selectedCategory.value = "Dual Screen";
          selectedDevice.value = "Surface Duo 2";
          selectedDeviceLabel.value = "general.menu.duo.name";
          break;
        case `/${store.state.actualLang}/stay-in-the-know-even-when-its-closed`:
          selectedCategory.value = "Dual Screen";
          selectedDevice.value = "Surface Duo 2";
          selectedDeviceLabel.value = "general.menu.duo.name";
          break;
        // New change for 9/21 ----
        case `/${store.state.actualLang}/device/hub3`:
          selectedCategory.value = "Large Screens";
          selectedDevice.value = "Surface Hub 3";
          selectedDeviceLabel.value = "general.menu.hubs3.name";
          break;
        // ----
        case `/${store.state.actualLang}/device/hub2s`:
          selectedCategory.value = "Large Screens";
          selectedDevice.value = "Surface Hub 2S";
          selectedDeviceLabel.value = "general.menu.hubs.name";
          break;
        case `/${store.state.actualLang}/collaborate-anywhere`:
          selectedCategory.value = "Large Screens";
          selectedDevice.value = "Surface Hub 2S";
          selectedDeviceLabel.value = "general.menu.hubs.name";
          break;
        case `/${store.state.actualLang}/join-meetings-seamlessly`:
          selectedCategory.value = "Large Screens";
          selectedDevice.value = "Surface Hub 2S";
          selectedDeviceLabel.value = "general.menu.hubs.name";
          break;
        case `/${store.state.actualLang}/multiscreen-meeting`:
          selectedCategory.value = "Large Screens";
          selectedDevice.value = "Surface Hub 2S";
          selectedDeviceLabel.value = "general.menu.hubs.name";
          break;
        case `/${store.state.actualLang}/personal-office`:
          selectedCategory.value = "Large Screens";
          selectedDevice.value = "Surface Hub 2S";
          selectedDeviceLabel.value = "general.menu.hubs.name";
          break;
        case `/${store.state.actualLang}/device/studio2plus`:
          selectedCategory.value = "Large Screens";
          selectedDevice.value = "Surface Studio 2+";
          selectedDeviceLabel.value = "general.menu.studio2.name";
          break;
        case `/${store.state.actualLang}/creative-freedom`:
          selectedCategory.value = "Large Screens";
          selectedDevice.value = "Surface Studio 2+";
          selectedDeviceLabel.value = "general.menu.studio2.name";
          break;
        case `/${store.state.actualLang}/color-accuracy`:
          selectedCategory.value = "Large Screens";
          selectedDevice.value = "Surface Studio 2+";
          selectedDeviceLabel.value = "general.menu.studio2.name";
          break;
        case `/${store.state.actualLang}/autopilot-user-experience`:
          selectedCategory.value = "Security, management, and deployment";
          selectedDevice.value = "Security, management, and deployment";
          selectedDeviceLabel.value = "general.menu.management.name";
          break;
        case `/${store.state.actualLang}/autopilot-admin-experience`:
          selectedCategory.value = "Security, management, and deployment";
          selectedDevice.value = "Security, management, and deployment";
          selectedDeviceLabel.value = "general.menu.management.name";
          break;
        case `/${store.state.actualLang}/device/headphones`:
          selectedCategory.value = "Surface accessories";
          selectedDevice.value = "Surface accessories";
          selectedDeviceLabel.value = "general.menu.accessories.name";
          break;
        case `/${store.state.actualLang}/device/proFlexKeyboard`:
          selectedCategory.value = "Surface accessories";
          selectedDevice.value = "Surface accessories";
          selectedDeviceLabel.value = "general.menu.accessories.name";
          break;
        case `/${store.state.actualLang}/device/earbuds`:
          selectedCategory.value = "Surface accessories";
          selectedDevice.value = "Surface accessories";
          selectedDeviceLabel.value = "general.menu.accessories.name";
          break;
      }
    }

    watch(
      () => store.state.isMenuOpen,
      newVal => {
        if (newVal === true) {
          openMenu();
        } else {
          focusTrapRef.value.deactivate();
        }
      }
    );

    function openMenu() {
      menuIsOpening.value = true;
      setSelectedDevice(route.path);
      anime.set(".menu__content-inner > div", {
        translateY: 40,
      });

      anime({
        targets: ".menu__content-inner > div",
        duration: 400,
        translateY: [40, 0],
        opacity: [0, 1],
        delay: anime.stagger(80, { start: 125 }),
        complete: () => {
          menuIsOpening.value = false;
          focusTrapRef.value.activate();
        },
        easing: "easeOutQuint",
      });
    }

    function closeMenu(path, isFamilyExplorerLink, deviceName = "") {
      emitter.emit("globalResize");
      changingRoutes.value = true;
      anime.set(".menu__fade", {
        opacity: 0,
      });

      anime({
        targets: ".menu__fade",
        duration: 250,
        opacity: 1,
        easing: "linear",
        complete: () => {
          store.commit("updateIsMenuOpen", false);
          setTimeout(() => {
            if (isFamilyExplorerLink && store.state.isFamilyExplorer) {
              if (path.split("/").pop() === `${store.state.actualLang}`) {
                emitter.emit("famExpGoToExplorerPath", "");
              } else {
                emitter.emit("famExpGoToExplorerPath", path.split("/").pop());
                router.replace({ path: path });
              }
              router.push({ path });
            } else {
              store.commit("reset", store.state);
              router.push({ path });
            }
            store.commit("updateFamExpDeviceLoading", deviceName);
          }, 200);
          setTimeout(() => {
            anime.set(".menu__fade", {
              opacity: 0,
            });
            changingRoutes.value = false;
          }, 400);
        },
      });
    }

    function updateRating(star) {
      star_selected.value = star;
      localStorage.setItem("starRating", star);
    }

    function star_class(element) {
      if (star_selected.value >= element) {
        return "star_element_active";
      } else if (star_selected.value < element && element <= star_hover.value) {
        return "star_element_hover";
      }
    }

    return {
      menuIsOpen,
      menuRoutes,
      router,
      route,
      stars,
      star_selected,
      star_hover,
      store,
      openMenu,
      closeMenu,
      menuIsOpening,
      changingRoutes,
      selectedDevice,
      isEndingSidebarTransitioning,
      loaderIsShowing,
      updateRating,
      star_class,
      focusTrapRef,
      homeRoute,
      selectedCategory,
      calcRoute,
    };
  },
};
</script>

<style lang="scss" scoped>
.menu {
  $menuAnimationLength: 0.25s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  touch-action: none;
  cursor: auto;
  //   z-index: 101;

  &__content {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: clamp(410px, 25vw, 480px);
    height: 100%;
    z-index: 101;
    pointer-events: all;
    touch-action: auto;
    padding: $absoluteOuterPadding 3.25vw 3.25vw;

    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba($darkGray, 0.25);
    will-change: transform;

    &-inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-y: auto;
      height: calc(100% - 155px); //calc(100% - 98px);
      width: 100%;
      //   @media screen and (min-height: 900px) {
      //     height: calc(100% - 92px); //calc(100% - 98px);
      //   }
    }

    @include backgroundBlur(#e6e6e6, 0.975, 0.8, 15px);
  }
  .home-btn {
    margin-top: 9px;
    margin-bottom: 24px;

    :deep(a) {
      font-weight: 600;
    }
  }

  &__close {
    position: absolute;
    top: $absoluteOuterPadding;
    right: $absoluteOuterPadding;
    z-index: 102;
    cursor: pointer;
    padding: 11px 11px 7px 11px;
    border-radius: 100%;
    transition: all 250ms ease;
    transform: scale3d(1, 1, 1);
    background: transparent;
    border: none;

    img {
      position: relative;
      transition: all 250ms ease;
      transform: scale3d(1, 1, 1);
      width: 21px;
    }
    &:hover {
      transform: scale3d(1.1, 1.1, 1.1);
      img {
        transform: scale3d(1.1, 1.1, 1.1);
      }
    }
  }

  &__button {
    position: absolute;
    top: $absoluteOuterPadding;
    right: $absoluteOuterPadding;
    pointer-events: all;
    touch-action: auto;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 2px;
    border: none;
    padding: 8px;
    cursor: pointer;
    z-index: 98;
    transition: all 250ms ease;

    > div {
      background: #fff;
      height: 2px;
      width: 20px;
      margin: 4px auto;
    }

    &--hidden {
      opacity: 0;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.75);
    }
    &:focus {
      background: rgba(0, 0, 0, 0.75);
    }
  }
  &__fade {
    background: #e6e6e6;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 106;
    opacity: 0;
  }

  .menu-slideout-leave-active,
  .menu-slideout-enter-active {
    transition: all 0.25s ease-in;
  }

  .menu-slideout-leave-from,
  .menu-slideout-enter-to {
    transform: translate3d(0, -50%, 0);
  }
  .menu-slideout-leave-to,
  .menu-slideout-enter-from {
    transform: translate3d(clamp(360px, 25vw, 480px), -50%, 0);
  }

  .menu--chaningRoutes .menu-slideout-leave-active {
    transition: none;
  }
}

.anim_start_rating {
  margin-top: 0px;
  //   @media screen and (min-height: 900px) {
  //     margin-top: -50px;
  //   }
}
</style>
