import { createRouter, createWebHashHistory } from "vue-router";
import { store } from "@/data/store.js";
import { trackRouter } from "vue-gtag-next";
import VALID_LANGUAGES from "../i18nLanguage";

const getTitleFromName = name => {
  //   console.log("getting name");
  switch (name) {
    case "pro8":
      return "Surface Pro 8";
    case "pro9":
      return "Surface Pro 9";
    case "pro11":
      return "Surface Pro";
    case "pro10":
      return "Surface Pro 10";
    // New change for 9/21 ----
    case "laptopStudio2":
      return "Surface Laptop Studio 2";
    // ----
    case "laptopStudio":
      return "Surface Laptop Studio";
    case "proX":
      return "Surface Pro X";
    case "earbuds":
      return "Surface Earbuds";
    // New change for 9/21 ----
    case "go4":
      return "Surface Go 4";
    // ----
    case "go3":
      return "Surface Go 3";
    case "headphones":
      return "Surface Headphones 2+";
    case "proFlexKeyboard":
      return "Surface Pro Flex Keyboard";
    case "laptop4":
      return "Surface Laptop 4";
    case "laptop5":
      return "Surface Laptop 5";
    case "laptop6":
      return "Surface Laptop 6";
    case "laptop7":
      return "Surface Laptop";
    // New change for 9/21 ----
    case "laptopGo3":
      return "Surface Laptop Go 3";
    // ----
    case "laptopGo2":
      return "Surface Laptop Go 2";
    case "studio2":
      return "Surface Studio 2+";
    case "hub3":
      return "Surface Hub 3";
    case "hub2s":
      return "Surface Hub 2S";
    case "duo2":
      return "Surface Duo 2";
  }
};

const getDefault = () => {
  let userLanguage = window.navigator.userLanguage || window.navigator.language;
  if (VALID_LANGUAGES.includes(userLanguage.toLowerCase())) {
    store.commit("updateActualLang", userLanguage.toLowerCase());
  } else {
    store.commit("updateActualLang", VALID_LANGUAGES[0].toLowerCase());
  }
  //store.commit("updateActualLang", "en");
  return "/" + store.state.actualLang;
};

const routes = [
  {
    path: "/",
    redirect: getDefault(),
  },
  {
    path: "/:lang",
    name: "Explore Surface for Business",
    component: () =>
      import(
        /* webpackChunkName: "family-explorer" */ "../modules/familyExplorer/FamilyExplorer.vue"
      ),
  },
  {
    path: "/:lang/device/:device",
    name: "Device Explorer",
    component: () =>
      import(
        /* webpackChunkName: "family-explorer" */ "../modules/familyExplorer/FamilyExplorer.vue"
      ),
  },
  {
    path: "/:lang/productivity-and-inking",
    name: "Productivity and inking",
    component: () =>
      import(
        /* webpackChunkName: "surface-go" */ "../modules/surfaceGo_A/SurfaceGo_A.vue"
      ),
  },
  {
    path: "/:lang/maps-on-the-go",
    name: "Maps on the go",
    component: () =>
      import(
        /* webpackChunkName: "surface-go" */ "../modules/surfaceGo_B/SurfaceGo_B.vue"
      ),
  },
  {
    path: "/:lang/taking-a-picture",
    name: "Taking a picture",
    component: () =>
      import(
        /* webpackChunkName: "surface-go" */ "../modules/surfaceGo_C/SurfaceGo_C.vue"
      ),
  },
  {
    path: "/:lang/autopilot-user-experience",
    name: "Autopilot user experience",
    component: () =>
      import(
        /* webpackChunkName: "surface-go" */ "../modules/surfaceGo_D/SurfaceGo_D.vue"
      ),
  },
  {
    path: "/:lang/collaborate-anywhere",
    name: "Collaborate anywhere",
    component: () =>
      import(
        /* webpackChunkName: "surface-hub" */ "../modules/surfaceHub_A/surfaceHub_A.vue"
      ),
  },
  {
    path: "/:lang/join-meetings-seamlessly",
    name: "Join meetings seamlessly",
    component: () =>
      import(
        /* webpackChunkName: "surface-hub" */ "../modules/surfaceHub_B/surfaceHub_B.vue"
      ),
  },
  {
    path: "/:lang/multiscreen-meeting",
    name: "Multiscreen meeting",
    component: () =>
      import(
        /* webpackChunkName: "surface-hub" */ "../modules/surfaceHub_C/surfaceHub_C.vue"
      ),
  },
  {
    path: "/:lang/personal-office",
    name: "Personal office",
    component: () =>
      import(
        /* webpackChunkName: "surface-hub" */ "../modules/surfaceHub_D/surfaceHub_D.vue"
      ),
  },
  {
    path: "/:lang/precision-note-taking",
    name: "Precision note-taking",
    component: () =>
      import(
        /* webpackChunkName: "surface-pro" */ "../modules/surfacePro_C/SurfacePro_C.vue"
      ),
  },
  {
    path: "/:lang/autopilot-admin-experience",
    name: "Autopilot admin experience",
    component: () =>
      import(
        /* webpackChunkName: "surface-pro" */ "../modules/surfaceProX_B/surfaceProX_B.vue"
      ),
  },
  {
    path: "/:lang/touchscreen-display",
    name: "Touchscreen Display",
    component: () =>
      import(
        /* webpackChunkName: "surface-laptop-go" */ "../modules/surfaceLaptopGo_A/SurfaceLaptopGo_A.vue"
      ),
  },

  {
    path: "/:lang/desktop-on-the-go",
    name: "Desktop on the go",
    component: () =>
      import(
        /* webpackChunkName: "surface-laptop-go" */ "../modules/surfaceLaptopGo_B/SurfaceLaptopGo_B.vue"
      ),
  },

  {
    path: "/:lang/lightweight-performance",
    name: "Lightweight performance",
    component: () =>
      import(
        /* webpackChunkName: "surface-laptop" */ "../modules/surfaceLaptop_A/SurfaceLaptop_A.vue"
      ),
  },
  {
    path: "/:lang/responsive-trackpad",
    name: "Responsive trackpad",
    component: () =>
      import(
        /* webpackChunkName: "surface-laptop" */ "../modules/surfaceLaptop_B/SurfaceLaptop_B.vue"
      ),
  },
  {
    path: "/:lang/best-in-class-meetings",
    name: "Best in class meetings",
    component: () =>
      import(
        /* webpackChunkName: "surface-laptop" */ "../modules/surfaceLaptop_C/SurfaceLaptop_C.vue"
      ),
  },
  {
    path: "/:lang/live-inking",
    name: "Live inking",
    component: () =>
      import(
        /* webpackChunkName: "surface-book" */ "../modules/surfaceBook_A/SurfaceBook_A.vue"
      ),
  },
  {
    path: "/:lang/ideate-with-microsoft-whiteboard",
    name: "Ideate with Microsoft Whiteboard",
    component: () =>
      import(
        /* webpackChunkName: "surface-laptop-sudio" */ "../modules/surfaceRowan_A/surfaceRowan_A.vue"
      ),
  },

  {
    path: "/:lang/create-dynamic-presentations-with-powerPoint",
    name: "Create dynamic presentations with PowerPoint",
    component: () =>
      import(
        /* webpackChunkName: "surface-laptop-sudio" */ "../modules/surfaceRowan_B/surfaceRowan_B.vue"
      ),
  },
  {
    path: "/:lang/productivity-with-natural-inputs",
    name: "Productivity with natural inputs",
    component: () =>
      import(
        /* webpackChunkName: "surface-pro-9" */ "../modules/surfaceDelia_A/surfaceDelia_A.vue"
      ),
  },
  {
    path: "/:lang/performance-by-your-definition",
    name: "Performance by your definition",
    component: () =>
      import(
        /* webpackChunkName: "surface-pro-9" */ "../modules/surfaceDelia_B/surfaceDelia_B.vue"
      ),
  },
  {
    path: "/:lang/stay-connected-with-5g",
    name: "Stay connected with 5G",
    component: () =>
      import(
        /* webpackChunkName: "surface-pro-9" */ "../modules/surfaceHarris_A/surfaceHarris_A.vue"
      ),
  },
  {
    path: "/:lang/creative-agility",
    name: "Creative agility",
    component: () =>
      import(
        /* webpackChunkName: "surface-book" */ "../modules/surfaceBook_D/SurfaceBook_D.vue"
      ),
  },

  {
    path: "/:lang/creative-freedom",
    name: "Creative freedom",
    component: () =>
      import(
        /* webpackChunkName: "surface-studio" */ "../modules/surfaceStudio_A/SurfaceStudio_A.vue"
      ),
  },
  {
    path: "/:lang/color-accuracy",
    name: "Color accuracy",
    component: () =>
      import(
        /* webpackChunkName: "surface-studio" */ "../modules/surfaceStudio_B/SurfaceStudio_B.vue"
      ),
  },
  {
    path: "/:lang/prepare-and-present-with-surface-duo-2",
    name: "Prepare and present with Surface Duo 2",
    component: () =>
      import(
        /* webpackChunkName: "surface-studio" */ "../modules/surfaceDuo_A/surfaceDuo_A.vue"
      ),
  },
  {
    path: "/:lang/do-more-with-outlook-on-surface-duo-2",
    name: "Do more with Outlook on Surface Duo 2",
    component: () =>
      import(
        /* webpackChunkName: "surface-studio" */ "../modules/surfaceDuo_B/surfaceDuo_B.vue"
      ),
  },
  {
    path: "/:lang/stay-in-the-know-even-when-its-closed",
    name: "Stay in the know even when its closed",
    component: () =>
      import(
        /* webpackChunkName: "surface-studio" */ "../modules/surfaceDuo_C/surfaceDuo_C.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "Device Explorer") {
    document.title = "Device Explorer - " + getTitleFromName(to.params.device);
  } else document.title = to.name;
  next();
});

if (
  window.location.host === "explore-surface-staging.netlify.app" ||
  window.location.host === "exploresurface.com" ||
  window.location.host === "www.exploresurface.com"
) {
  trackRouter(router, {
    template(to) {
      if (to.name === "Device Explorer") {
        return {
          page_title: "Device Explorer - " + getTitleFromName(to.params.device),
        };
      } else {
        return {
          page_title: to.name,
        };
      }
    },
  });
}

export default router;
