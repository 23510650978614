function sendRequest(payLoad) {
  const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
  const code = process.env.VUE_APP_BACKEND_CODE;
  let url = `${baseUrl}/api/Feedback?code=${code}`;
  if (window.location.host !== "exploresurface.com") {
    //replace url with staging url
    url = `${baseUrl}/api/Feedback?code=${code}`;
  }
  //console.log(payLoad);
  var xhr = new XMLHttpRequest();
  xhr.withCredentials = true;

  xhr.addEventListener("readystatechange", function() {
    if (this.readyState === 4) {
      //console.log(this.responseText);
    }
  });

  xhr.open("POST", url);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.send(JSON.stringify(payLoad));
}

export { sendRequest };
