<template>
  <FocusTrap :active="isFilterOpen">
    <div
      class="filter_menu"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <!-- <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.spro9"
          id="checkboxPro9"
          tabindex="0"
          @change="onCheckBoxChange('spro9', !$store.state.activeDevices.spro9)"
        />
        <label for="checkboxPro9">Surface Pro 9</label>
      </div> -->

      <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.spro10"
          id="checkboxPro10"
          @change="onCheckBoxChange('spro10', !$store.state.activeDevices.spro10)"
        />
        <label for="checkboxPro10">Surface Pro 10</label>
      </div>

      <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.spro11"
          id="checkboxBigrock"
          @change="onCheckBoxChange('spro11', !$store.state.activeDevices.spro11)"
        />
        <label for="checkboxBigrock">Surface Pro</label>
      </div>

      <!-- New change for 9/21 ---- -->  
      <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.sgo4"
          id="checkboxGo4"
          @change="onCheckBoxChange('sgo4', !$store.state.activeDevices.sgo4)"
        />
        <label for="checkboxGo4">Surface Go 4</label>
      </div>

      <!-- <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.sgo3"
          id="checkboxGo3"
          @change="onCheckBoxChange('sgo3', !$store.state.activeDevices.sgo3)"
        />
        <label for="checkboxGo3">Surface Go 3</label>
      </div> -->
      <!-- ---- -->

      <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.slaptop6"
          id="checkboxLaptop6"
          @change="
            onCheckBoxChange('slaptop6', !$store.state.activeDevices.slaptop6)
          "
        />
        <label for="checkboxLaptop6">Surface Laptop 6</label>
      </div>

      <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.slaptop7"
          id="checkboxHartwell"
          @change="
            onCheckBoxChange('slaptop7', !$store.state.activeDevices.slaptop7)
          "
        />
        <label for="checkboxHartwell">Surface Laptop</label>
      </div>

      <!-- <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.slaptop5"
          id="checkboxLaptop5"
          @change="
            onCheckBoxChange('slaptop5', !$store.state.activeDevices.slaptop5)
          "
        />
        <label for="checkboxLaptop5">Surface Laptop 5</label>
      </div> -->
      
      <!-- New change for 9/21 ---- -->
      <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.slaptops2"
          id="checkboxLaptopStudio2"
          @change="
            onCheckBoxChange('slaptops2', !$store.state.activeDevices.slaptops2)
          "
        />
        <label for="checkboxLaptopStudio">Surface Laptop Studio 2</label>
      </div>

      <!-- <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.slaptops"
          id="checkboxLaptopStudio"
          @change="
            onCheckBoxChange('slaptops', !$store.state.activeDevices.slaptops)
          "
        />
        <label for="checkboxLaptopStudio">Surface Laptop Studio</label>
      </div> -->
      <!-- ---- -->

      <!-- New change for 9/21 ---- -->
      <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.slaptopgo3"
          id="checkboxLaptopGo3"
          @change="
            onCheckBoxChange('slaptopgo3', !$store.state.activeDevices.slaptopgo3)
          "
        />
        <label for="checkboxLaptopGo">Surface Laptop Go 3</label>
      </div>

      <!-- <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.slaptopgo"
          id="checkboxLaptopGo"
          @change="
            onCheckBoxChange('slaptopgo', !$store.state.activeDevices.slaptopgo)
          "
        />
        <label for="checkboxLaptopGo">Surface Laptop Go 2</label>
      </div> -->
      <!-- ----  -->

      <!-- <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.sduo2"
          id="checkboxDuo2"
          @change="onCheckBoxChange('sduo2', !$store.state.activeDevices.sduo2)"
        />
        <label for="checkboxDuo2">Surface Duo 2</label>
      </div> -->

      <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.sstudio2"
          id="checkboxStudio2"
          @change="
            onCheckBoxChange('sstudio2', !$store.state.activeDevices.sstudio2)
          "
        />
        <label for="checkboxStudio2">Surface Studio 2+</label>
      </div>

      <!-- New change for 9/21 ---- -->

      <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.shub385"
          id="checkboxHub385"
          @change="
            onCheckBoxChange('shub385', !$store.state.activeDevices.shub385)
          "
        />
        <label for="checkboxHub385">Surface Hub 3 85"</label>
      </div>

      <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.shub350"
          id="checkboxHub350"
          @change="onCheckBoxChange('shub350', !$store.state.activeDevices.shub350)"
        />
        <label for="checkboxHub350">Surface Hub 3 50"</label>
      </div>


      <!-- <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.shub2s"
          id="checkboxHub2S"
          @change="
            onCheckBoxChange('shub2s', !$store.state.activeDevices.shub2s)
          "
        />
        <label for="checkboxHub2S">Surface Hub 2S 85"</label>
      </div>

      <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.shubs"
          id="checkboxHubS"
          @change="onCheckBoxChange('shubs', !$store.state.activeDevices.shubs)"
        />
        <label for="checkboxHubS">Surface Hub 2S 50"</label>
      </div> -->
      <!-- ----  -->

      <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.sproFlexKeyboard"
          id="checkboxToba"
          @change="
            onCheckBoxChange('sproFlexKeyboard', !$store.state.activeDevices.sproFlexKeyboard)
          "
        />
        <label for="checkboxToba">Surface Pro Flex Keyboard</label>
      </div>

      <!-- <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.shead2"
          id="checkboxHeadphones2"
          @change="
            onCheckBoxChange('shead2', !$store.state.activeDevices.shead2)
          "
        />
        <label for="checkboxHeadphones2">Surface Headphones 2+</label>
      </div>

      <div class="item">
        <input
          type="checkbox"
          :checked="$store.state.activeDevices.sear"
          id="checkboxEarbuds"
          @change="onCheckBoxChange('sear', !$store.state.activeDevices.sear)"
        />
        <label for="checkboxEarbuds">Surface Earbuds</label>
      </div> -->

      <div class="separator" />

      <div class="item share" v-if="!share">
        <button id="share-button" @click="share = !share">
          <svg
            width="20"
            height="17"
            viewBox="0 0 29 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.000488281 26V7.48486H1.83887V24.1616H20.748V22.3232L22.5863 20.6162V26H0.000488281Z"
              fill="#3A3A3A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.67652 20.3536C5.19504 18.6465 5.6767 13.9192 8.69693 10.2424C11.9442 6.28932 15.7878 5.51515 18.808 5.51515V0L28.7878 10.2424L18.808 20.3536V14.9697C18.7821 14.9697 18.7559 14.9697 18.7297 14.9697C17.1627 14.9693 15.0751 14.9688 11.7171 16.2828C8.97501 17.3559 6.88211 19.2589 5.9303 20.1243C5.83381 20.212 5.74904 20.2891 5.67652 20.3536ZM7.87879 16.1515C8.01011 14.4444 9.39653 11.7821 12.0808 9.58586C14.7262 7.42148 18.1423 7.4592 20.111 7.48094C20.2922 7.48295 20.4612 7.48481 20.6162 7.48481V4.46465L26.2626 10.2424L20.6162 16.1515V13C20.4531 13 20.2606 12.9975 20.043 12.9946C18.508 12.9745 15.7263 12.938 13.2626 13.6566C10.1111 14.5758 8.53536 15.6262 7.87879 16.1515Z"
              fill="#3A3A3A"
            />
          </svg>
          Share filtered site
        </button>
      </div>
      <div class="share-view" v-else>
        <button
          class="share-close"
          @click="
            share = !share;
            copied = !copied;
          "
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 0.5L14.5 14" stroke="#000" stroke-width="1.25" />
            <path d="M14.5 0.5L0.999999 14" stroke="#000" stroke-width="1.25" />
          </svg>
        </button>
        <div class="icon" v-if="copied">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="14.5" cy="14.5" r="14.5" fill="#9BC45C" />
            <path
              d="M6 15.2222L11.2308 21L23 8"
              stroke="#F4F4F4"
              stroke-width="2"
            />
          </svg>
        </div>
        <p class="success" v-if="copied">Link copied!</p>
        <div class="text-link">
          <input
            v-on:focus="$event.target.select()"
            ref="clone"
            readonly
            :value="$store.state.shareUrl"
          />
          <button @click="copy" class="copy-button">Copy</button>
        </div>
        <p class="information">
          The linked site will display the currently selected devices
        </p>
      </div>
    </div>
  </FocusTrap>
</template>
<script>
import { ref, watch, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { FocusTrap } from "focus-trap-vue";

let OPEN_TIMEOUT = null;

export default {
  components: { FocusTrap },
  setup() {
    let store = useStore();
    let isHovered = ref(false);
    let clone = ref(null);
    let share = ref(false);
    let copied = ref(false);

    watch(
      () => isHovered.value,
      newVal => {
        if (!newVal && isFilterOpen.value) {
          setAutoCloseTimeout();
        } else {
          clearAutoCloseTimeout();
        }
      }
    );

    const isFilterOpen = computed({
      get() {
        return store.state.isFilterOpen;
      },
      set(newValue) {
        store.commit("updateFilterOpen", newValue);
      },
    });

    let clearAutoCloseTimeout = () => {
      if (OPEN_TIMEOUT) {
        window.clearTimeout(OPEN_TIMEOUT);
        OPEN_TIMEOUT = null;
      }
    };

    let setAutoCloseTimeout = () => {
      clearAutoCloseTimeout();
      OPEN_TIMEOUT = window.setTimeout(() => {
        isFilterOpen.value = false;
      }, 4500);
    };

    let onCheckBoxChange = (id, value) => {
      store.commit("updateDevices", { id, value });
    };

    onMounted(() => {
      store.commit("updateDevices");
    });

    let copy = () => {
      clone.value.focus();
      document.execCommand("copy");
      copied.value = true;
    };

    return {
      isFilterOpen,
      isHovered,
      onCheckBoxChange,
      copy,
      share,
      copied,
      clone,
    };
  },
};
</script>
<style lang="scss" scoped>
.filter_menu {
  position: absolute;
  top: 42px;
  right: 62px;
  width: 225px;
  min-height: 488px;
  z-index: 30;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  button:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

  div.item {
    padding-top: 12px;
    padding-left: 20px;
    color: #3a3a3a;
    font-weight: 400;
    font-size: 14px;

    label {
      margin-left: 15px;
    }

    &.share {
      padding-top: 10px;
    }

    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;

      svg {
        margin-right: 10px;
      }
    }
  }

  .share-view {
    padding: 18px 20px;
    min-height: 92px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .share-close {
      align-self: flex-end;
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      margin-top: -10px;
    }

    div.icon {
      display: flex;
      justify-content: center;
    }

    p.success {
      size: 18px;
      color: #3a3a3a;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
    }

    div.text-link {
      display: flex;
      flex-direction: row;

      .copy-button {
        background: #4492c5;
        color: #fff;
        border: none;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 3px;
        font: inherit;
        cursor: pointer;
      }
    }

    p.information {
      font-style: italic;
      font-weight: 400;
      font-size: 10.5px;
      line-height: 14px;
      text-align: center;
      margin-left: -5px;
      color: #3a3a3a;
    }
  }

  div.separator {
    background-color: #3a3a3a;
    width: 202px;
    height: 1px;
    align-self: center;
    margin-top: 14px;
  }
}
.close {
  display: none;
}
</style>
