<!-- eslint-disable prettier/prettier -->
<template>
  <div class="main-menu">
    <ul>
      <li class="left-align">
        <a
          href="https://go.microsoft.com/fwlink/?linkid=2259814"
          target="_blank"
        >
          Consumer Health Privacy</a
        >
      </li>
      <li class="left-align">
        <a
          href="https://go.microsoft.com/fwlink/?LinkId=521839"
          target="_blank"
        >
          Privacy & Cookie</a
        >
      </li>
    </ul>
    <ul>
      <li class="right-align">
        <a :href="TcoCalculatorUrl" target="_blank">
          Total Cost of Ownership (TCO) Calculator</a
        >
      </li>
      <li class="right-align">
        <a :href="SusEstimatorUrl" target="_blank">
          Emissions Estimator (Sustainability)</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      TcoCalculatorUrl: process.env.VUE_APP_TCO_CALCULATOR_URL,
      SusEstimatorUrl: process.env.VUE_APP_SUS_ESTIMATOR_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-menu {
  z-index: 100;
  position: fixed;
  background-color: #333;
  width: 100%;
  color: #fff;
  bottom: 0;
  padding: 0px 40px;
  // new
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    // width: 50%;
    padding: 0px;

    li {
      font-size: 16px;
      padding: 10px; //15px

      a {
        color: inherit;
        text-decoration: none;
        padding-bottom: 5px;
        border-bottom: 1px solid #fff;
      }
    }

    // .left-align {
    //     font-size: 16px;
    //     padding: 10px; //15px
    //     justify-self: left;
    // }

    // .right-align {
    //     font-size: 16px;
    //     padding: 10px; //15px
    //     // align-items: right;
    //     justify-self: right;
    //     // margin-left: auto;
    //     // float: right;
    // }
  }
}
</style>
