//if actual user configuation is not one of these languages,
//the default value will be the first element of the array
// English on this case

//if this array is empty you will get an index out of bound error
// on index.js (router) and on i18n.js

// const VALID_LANGUAGES = ["en-us"];
const VALID_LANGUAGES = ["en-us", "es-es", "de-de", "fr-fr", "ja-jp"];

export default VALID_LANGUAGES;
