<template>
  <button
    class="buttonText"
    :class="{
      'buttonText--white-text': white,
      'buttonText--left': arrow === 'left',
      'buttonText--right': arrow === 'right',
    }"
  >
    {{ $t(text) }}
    <div v-if="arrow" class="buttonText__arrows-container">
      <div
        v-if="arrow === 'right'"
        class="buttonText__arrow buttonText__arrowRight"
      ></div>
      <div
        v-if="arrow === 'left'"
        class="buttonText__arrow buttonText__arrowLeft"
      ></div>
    </div>
  </button>
</template>

<script>
export default {
  setup(props) {
    return props;
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    white: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: String,
      default: "right",
    },
  },
};
</script>

<style lang="scss" scoped>
.buttonText {
  position: relative;
  @include text(bodyBold);
  background: transparent;
  color: $blue;
  border: none;
  display: flex;
  justify-items: space-between;
  align-items: center;
  padding: 0px 8px;
  transition: all 200ms ease;
  pointer-events: all;
  touch-action: auto;
  cursor: pointer;

  &--white-text {
    color: #fff;
  }

  &--left {
    padding: 0px 8px 0px 24px;
  }
  &--right {
    padding: 0px 24px 0px 8px;
  }

  &__arrows-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }
  &__arrow {
    position: absolute;
    content: url("data:image/svg+xml,%3Csvg width='7' height='9' viewBox='0 0 7 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 4.5L0.25 8.39711L0.25 0.602885L7 4.5Z' fill='%230078D4'/%3E%3C/svg%3E%0A");
    width: 12px;
    transition: 0.15s all ease;

    &Right {
      transform: translate(-25%, -50%);
      top: 50%;
      right: 0;
    }
    &Left {
      transform: translate(25%, -50%) rotate(180deg);
      top: 50%;
      left: 0;
    }
  }

  &:hover {
    .buttonText__arrow {
      &Right {
        transform: translate(0%, -50%);
        top: 50%;
        right: 0;
      }
      &Left {
        transform: translate(0%, -50%) rotate(180deg);
        top: 50%;
        left: 0;
      }
    }
  }
}
</style>
