<template>
  <FocusTrap :active="modalIsOpen" :initial-focus="() => $refs.closeBtn">
    <div class="disclaimer" :class="{ 'disclaimer--active': modalIsOpen }">
      <div class="disclaimer__modal-container">
        <div class="disclaimer__content" ref="closeBtn">
          <button
            class="disclaimer__close"
            tabindex="1"
            @click="toggleModal"
            :aria-label="$t('accessibility.closeModalFeedBack')"
          >
            <img
              src="@/assets/close-x.svg"
              v-bind:alt="$t('accessibility.closeModalFeedBack')"
            />
          </button>
          <div
            class="disclaimer__body"
            :class="{ 'disclaimer__body--center': submitted }"
          >
            <div v-if="!submitted">
              <h1 class="modal_title">{{ questions.title }}</h1>
              <div
                v-for="(element, index) in questions.questions"
                :key="index"
                class="modal_question"
              >
                <h2 class="modal_question_title">{{ element.question }}</h2>
                <div v-if="element.isRadio">
                  <div class="modal_radio_container">
                    <div
                      v-for="(answer, index2) in element.answers"
                      :key="index2"
                      class="modal_radio_container2"
                    >
                      <input
                        class="modal_radio_input"
                        :aria-label="answer.value"
                        type="radio"
                        :tabindex="1"
                        :id="answer.id"
                        :name="index"
                        v-on:keyup.enter="
                          $event.target.nextElementSibling.focus()
                        "
                        :value="answer.id"
                        v-model="picked[index]"
                      />
                      <h3 class="modal_radio_label">{{ answer.value }}</h3>
                    </div>
                  </div>
                </div>
                <div v-if="element.isTextArea">
                  <textarea
                    tabindex="1"
                    :aria-label="$t('accessibility.anyComment')"
                    class="modal_question_text"
                    :placeholder="element.placeHolder"
                    v-model="picked[index]"
                  ></textarea>
                </div>
              </div>
              <button
                class="modal_submit_button"
                tabindex="20"
                @click="submit()"
                :disabled="!picked[0] && !picked[1] && !picked[2] && !picked[3]"
              >
                {{ $t("general.scenes.submit") }}
              </button>
            </div>
            <div v-if="submitted" class="center_submit">
              <h1 class="submit_title">Success.</h1>
              <h2 class="submit_subtitle">Thank you for your feedback.</h2>
              <button class="submit_button_close" @click="toggleModal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { ref, watch } from "vue";
import { store } from "@/data/store.js";
import questions from "@/data/feedBack.json";
import { sendRequest } from "@/js/feedBack.js";
import { FocusTrap } from "focus-trap-vue";

export default {
  components: { FocusTrap },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const picked = ref([]);
    const modalIsOpen = ref(props.isOpen);
    const submitted = ref(false);

    watch(
      () => props.isOpen,
      () => {
        modalIsOpen.value = props.isOpen;
      }
    );

    function toggleModal() {
      modalIsOpen.value = false;
      store.commit("updateFeedBack", modalIsOpen.value);
      picked.value = [];
    }

    function submit() {
      let request = { site_name: "Surface for Business" };

      if (picked.value[0]) {
        request.understanding_improved = 3 - picked.value[0];
      }
      if (picked.value[1]) {
        request.content_valuable = 3 - picked.value[1];
      }
      if (picked.value[2]) {
        request.likely_to_purchase = 3 - picked.value[2];
      }
      if (picked.value[3]) {
        request.comments = picked.value[3];
      }

      sendRequest(request);

      submitted.value = true;
    }

    return {
      props,
      toggleModal,
      modalIsOpen,
      questions,
      picked,
      submit,
      submitted,
    };
  },
};
</script>

<style lang="scss" scoped>
.disclaimer {
  font-family: Segoe UI;
  font-style: normal;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100%;
  user-select: none;
  pointer-events: none;
  touch-action: none;

  &__modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    transition: all 0.25s ease-in-out;
    z-index: 100;
  }

  &__content {
    position: relative;
    width: calc(100% - 140px);
    max-width: 52%;
    max-height: 90%;
    min-height: 78vh;
    background: #ffffff;
    box-shadow: 0px 4px 44px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding-top: 3.14%;
    box-sizing: border-box;
    opacity: 0;
    transition: all 0.25s ease-in-out;
    display: flex;
    flex-direction: column;

    @media all and (max-width: 1400px) {
      max-width: 62%;
    }
    @include breakpoint("tablet") {
      max-width: 600px;
    }
  }

  &__body {
    overflow: auto;

    padding-left: 4%;
    padding-right: 4%;

    p {
      padding: 8px 0;
    }

    &--center {
      margin: auto;
    }
  }

  &__close {
    position: absolute;
    background: none;
    border: none;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }

  &--active {
    pointer-events: all;
    touch-action: auto;

    .disclaimer__modal-container {
      background: rgba(0, 0, 0, 0.66);
    }

    .disclaimer__content {
      opacity: 1;
    }
  }
}

.modal_title {
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  color: #4a4a4a;
}
.modal_question {
  margin-bottom: 30px;
}

.modal_question_title {
  font-weight: bold;
  font-size: 15px;
  line-height: 160%;
  color: #4a4a4a;
  max-width: 66%;
}

.modal_question_text::placeholder {
  opacity: 0.9;
}

.modal_question_text {
  cursor: auto;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  color: #000000;
  padding-left: 20px;
  min-width: 96.9%;
  min-height: 112px;
  resize: none;
}

.modal_radio_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.modal_radio_container2 {
  display: flex !important;
}
.modal_radio_label {
  pointer-events: none;
  margin-bottom: 0px;
  margin-top: 2px;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #323130;
  order: 1;
}
.modal_radio_input {
  width: 18px;
  height: 18px;
  vertical-align: text-bottom;
  margin-left: 0;
}

.modal_submit_button {
  background-color: #0078d4;
  border-radius: 2px;
  color: #ffffff;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  min-width: 21%;
  min-height: 5vh;
  border: 0px solid #0078d4;
  cursor: pointer;
}
.submit_title {
  font-weight: 600;
  font-size: 36px;
  line-height: 125%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #4a4a4a;
  margin-bottom: 0px;
}
.submit_subtitle {
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: #4a4a4a;
  margin-top: 0px;
  margin-bottom: 25px;
}

.submit_button_close {
  background-color: #0078d4;
  border-radius: 2px;
  color: #ffffff;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  min-width: 60%;
  min-height: 5vh;
  border: 0px solid #0078d4;
  cursor: pointer;
  margin-bottom: 74px;
}

.center_submit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: center;
}
</style>
