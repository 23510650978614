<template>
  <div class="app" :class="$i18n.locale">
    <div v-if="updateExists && isProductionUrl" class="app__updateNotification">
      {{ $t("general.update") }}
      <button @click="refreshApp">{{ $t("general.updatePage") }}</button>
    </div>
    <header>
      <!-- <transition name="quick-fade">
        <GuideToggle
          v-show="
            (!$store.state.loaderIsShowing && $store.state.isFamilyExplorer) ||
              (!$store.state.isFamilyExplorer && $store.state.pageIndex > 0)
          "
        />
      </transition> -->
      <transition name="quick-fade">
        <Guide v-show="!$store.state.loaderIsShowing" />
      </transition>
      <transition name="quick-fade">
        <SettingsDrawer
          v-show="
            (!$store.state.loaderIsShowing && $store.state.isFamilyExplorer) ||
              (!$store.state.isFamilyExplorer && $store.state.pageIndex > 0)
          "
        />
      </transition>
      <Menu />
    </header>
    <main>
      <router-view />
      <cookie-banner></cookie-banner>
      <AppOverlay />
      <FeedBack :isOpen="openFeedBack" v-if="$store.state.isFeedBackOpen" />
      <MainMenu v-show="!$store.state.loaderIsShowing" />
    </main>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import useEmitter from "@/js/composables/useEmitter";
import update from "@/mixins/update";
import Menu from "@/components/Menu.vue";
import MainMenu from "@/components/MainMenu.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import SettingsDrawer from "@/components/SettingsDrawer.vue";
import FeedBack from "@/components/FeedBack.vue";
// import GuideToggle from "@/components/GuideToggle";
import Guide from "@/components/Guide";

export default {
  mixins: [update],
  components: {
    Menu,
    AppOverlay,
    SettingsDrawer,
    FeedBack,
    // GuideToggle,
    Guide,
    MainMenu,
  },
  setup() {
    const store = useStore();
    const emitter = useEmitter();

    const openFeedBack = computed(() => {
      return store.state.isFeedBackOpen;
    });
    const isProductionUrl = computed(() => {
      return (
        window?.location?.host !== "exploresurface.com" ||
        window?.location?.host !== "explore-surface.vercel.app"
      );
    });

    function onResize() {
      store.dispatch("windowSize");
      emitter.emit("globalResize");
      //   console.log((window.innerHeight / window.innerWidth) * 1000);
    }

    onMounted(() => {
      nextTick(() => {
        onResize();
        window.addEventListener("resize", _.debounce(onResize, 10));
        store.dispatch("updateCurrentBrowser");
        let urlParams = new URLSearchParams(
          window.location.hash.substring(window.location.hash.indexOf("?"))
        );
        if (urlParams.has("filter")) {
          let enabledParams = urlParams.get("filter").split(",");
          store.commit("setDevices", enabledParams);
          console.log(enabledParams);
        }
      });
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", _.debounce(onResize, 10));
    });
    return { onResize, openFeedBack, store, isProductionUrl };
  },
};
</script>

<style lang="scss">
@import "@/styles/main.scss";
</style>

<style lang="scss" scoped>
.app {
  &__updateNotification {
    position: absolute;
    z-index: 10000;
    bottom: 15px;
    right: 15px;
    padding: 35px;
    background: grey;
  }
}
</style>

<style lang="scss">
html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  scroll-behavior: smooth;
}
@include ms-font-face("Segoe UI", "segoeui-westeuropean");
#app {
  font-family: "Segoe UI", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  height: 100%;
  background: #e6e6e6;
}
#nav {
  padding: 0;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
button {
  font-family: "Segoe UI", Arial, sans-serif;
}

.surface-business-ui-container {
  position: absolute;
  left: 0px;
  width: 100vw;
  height: var(--height-minus-footer-nav);
  @include breakpoint("tablet") {
    height: calc(98vh - 55px);
  }
  display: flex;
  align-items: center;
  z-index: 20;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  touch-action: none;
}
</style>
