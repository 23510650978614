<template>
  <div
    class="quality-toggle"
    :class="{
      'quality-toggle--background-active': !isExplorer,
    }"
  >
    <p>{{ $t("general.performance") }}</p>
    <div class="quality-toggle__buttons">
      <button
        v-for="(button, i) in DATA.qualityToggle"
        :key="i"
        class="quality-toggle__button"
        :class="{
          'quality-toggle__button--active':
            button.dataMode === $store.state.quality,
        }"
        :aria-label="button.ariaLabel"
        @click="setQuality(i)"
        :tabindex="isSelectable ? 0 : -1"
      >
        {{ $t(button.text) }}
      </button>
    </div>
  </div>
</template>
<script>
const data = {
  qualityToggle: [
    {
      text: "general.low",
      dataMode: "sd",
      ariaLabel: "Switch to low quality mode",
    },
    {
      text: "general.medium",
      dataMode: "md",
      ariaLabel: "Switch to medium quality mode",
    },
    {
      text: "general.high",
      dataMode: "hd",
      ariaLabel: "Switch to high quality mode",
    },
  ],
};

export default {
  props: {
    isSelectable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DATA: data,
    };
  },
  computed: {
    isExplorer() {
      let returnVal = false;
      const isDeviceExplorer = new RegExp(/^(.+)?\/device\/(.+)?$/);

      if (
        this.$route.path === `/` ||
        this.$route.path === `/${this.$store.state.actualLang}` ||
        this.$route.path === `/${this.$store.state.actualLang}/` ||
        isDeviceExplorer.test(this.$route.path)
      ) {
        returnVal = true;
      }

      return returnVal;
    },
  },
  methods: {
    setQuality(i) {
      this.$store.commit("setQuality", this.DATA.qualityToggle[i].dataMode);
    },
  },
};
</script>
<style lang="scss" scoped>
.quality-toggle {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;
  margin: 0 24px;

  @include breakpoint("mobile") {
    flex-direction: column;
    align-items: flex-start;
  }

  p {
    @include text(bodyBold);
    color: $extraDarkGray;
    padding-bottom: 0px;
    margin-right: 16px;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__button {
    height: 100%;
    margin: 0 0.2rem;
    cursor: pointer;
    border: none;
    background: none;
    color: #767676;
    font-size: 14px;
    transition: all 0.25s 0.1s ease-in-out;
    position: relative;
    opacity: 0.8;
    &:nth-of-type(1) {
      margin-left: 0;
      padding-left: 0;
    }
    &:nth-of-type(3) {
      margin-right: 0;
      padding-right: 0;
    }
    &:nth-child(2),
    &:nth-child(3) {
      &:after {
        content: "";
        height: 1px;
        width: 15px;
        position: absolute;
        bottom: 42%;
        transform: rotate(-90deg);
        background: $navGrey;
        transition: all 0.25s ease-in-out;
      }

      &:nth-child(2) {
        &:after {
          left: -10px;
        }
      }
      &:nth-child(3) {
        &:after {
          left: -10px;
        }
      }
    }
    &:hover,
    &--active {
      color: $extraDarkGray;
      opacity: 1;
      &:after {
        color: $extraDarkGray;
      }
    }
  }
}
</style>
