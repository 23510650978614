<template>
  <div
    class="menu-collapse"
    :class="{
      'menu-collapse--open': isOpen,
      'menu-collapse--first': index === 0,
      'menu-collapse--last': isLast,
      'menu-collapse--is-nested': isNested,
      'menu-collapse--padding-bottom': padBottom,
      'menu-animate-in': animateIn,
      'menu-collapse__disabled':
        isEnabled !== null ? !$store.state.activeDevices[isEnabled] : false,
    }"
  >
    <button
      class="menu-collapse__header"
      @click="$emit('button-click', item.name)"
      :tabindex="item.children ? 0 : -1"
      :aria-label="aria"
      :disabled="
        isEnabled !== null ? !$store.state.activeDevices[isEnabled] : false
      "
    >
      <div
        :class="{
          'menu-collapse__disabled':
            isEnabled !== null ? !$store.state.activeDevices[isEnabled] : false,
        }"
      >
        {{ title ? title : $t(item.displayName) }}
        <svg
          width="8"
          height="13"
          viewBox="0 0 8 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          :class="{ 'icon--open': isOpen }"
        >
          <path
            d="M0.692383 0.807617L6.38476 6.49999L0.692383 12.1924"
            stroke="currentColor"
          />
        </svg>
      </div>
    </button>
    <n-collapse-transition :show="isOpen" appear>
      <div class="menu-collapse__content" ref="menuCollapseContent">
        <slot></slot>
      </div>
    </n-collapse-transition>
  </div>
</template>

<script>
import { watch, ref, nextTick } from "vue";
import { NCollapseTransition } from "naive-ui";
import anime from "animejs";

export default {
  emits: ["button-click"],
  components: { NCollapseTransition },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    aria: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 1,
    },
    animateIn: {
      type: Boolean,
      defautl: false,
    },
    padBottom: {
      type: Boolean,
      default: false,
    },
    isNested: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    isEnabled: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const menuCollapseContent = ref(null);
    watch(
      () => props.isOpen,
      newVal => {
        if (newVal) {
          nextTick(() => {
            let targets = menuCollapseContent.value.querySelectorAll(
              ".menu-animate-in"
            );

            anime.set(targets, {
              opacity: 0,
              translateY: 24,
            });
            anime({
              targets: targets,
              duration: props.padBottom && targets.length < 7 ? 400 : 300,
              opacity: [0, 1],
              translateY: [24, 0],
              delay: anime.stagger(targets.length > 7 ? 50 : 100),
              easing: "cubicBezier(0.25,0.1,0.25,1)",
            });
          });
        }
      }
    );

    return { props, menuCollapseContent };
  },
};
</script>

<style lang="scss" scoped>
.menu-collapse {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &__disabled {
    pointer-events: none;
    touch-action: none;

    color: #9b9b9b !important;
  }

  &__content {
    margin-left: 24px;
  }

  &--first {
    &:before {
      content: "";
      position: absolute;
      display: block;
      background: #bdbdbd;
      height: 1px;
      width: 100%;
      top: 0;
      left: 0;
    }
  }
  &:after {
    content: "";
    position: absolute;
    display: block;
    background: #bdbdbd;
    height: 1px;
    width: 100%;
    bottom: -1px;
    left: 0;
  }
  &--last.menu-collapse--is-nested {
    &:after {
      display: none;
    }
  }

  &:hover {
    button {
      color: $darkGray;
    }
  }

  button {
    position: relative;
    width: 100%;
    padding: 0px 16px 0px 4px;
    min-height: 60px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-weight: 400;
    margin: 0;
    @include text(bodyBold);
    line-height: 130%;
    color: $extraDarkGray;
    text-align: left;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
    }

    .icon {
      transform: rotate(90deg);
      transition: transform 0.25s ease-in-out;
      transform-origin: center center;

      &--open {
        transform: rotate(-90deg);
      }
    }
  }
  &--padding-bottom {
    /*.menu-collapse__content {
      padding-bottom: 24px;
    }*/
    .n-collapse-transition {
      margin-top: -6px;
    }
  }
}
</style>
